import React, {FC, useEffect, useState} from 'react'

import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link, useParams} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {BaseRequest, gereeListReq, magicnum} from '../../../services/Request'
import {CDN_URL, GereeDelete, GereeList, InvoiceGet} from '../../../services/main'
import {dateFormat, dateTimeFormat, isNullOrEmpty} from '../../../services/Utils'
import {KTIcon} from '../../../_metronic/helpers'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
import CustomModal from '../../modules/CustomModal/modal'
import ContractPage from './contractPDF'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]
type IProps = {
  id?: any
  type?: any
  uid?: any
  org_rd?: any
}
const ContractListPage: FC<IProps> = ({type, uid, org_rd}) => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [item, setItem] = useState(undefined)
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [rd, setRd] = useState('')
  const [showFilter, setShowFilter] = useState(false)
  const [showGereeModal, setShowGereeModal] = useState(false)
  const [invoiceData, setInvoiceData] = useState([])

  useEffect(() => {
    list()
    if(!isNullOrEmpty(org_rd)){
      setRd(org_rd)
    }
  }, [PID, org_rd])

  const list = () => {
    setLoading(true)
    var req = gereeListReq
    req.filter.uid = !isNullOrEmpty(uid) ? uid : ''
    req.filter.org_rd = rd
    req.filter.invoice_id = ''
    req.filter.org_id = ''
    req.pid = PID
    req.pSize = sizePerPage
    GereeList(req, onSuccess, onFailed, 'POST')
  }

  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }

  const onFailed = (err: any) => {
    setLoading(false)
    console.log(err)
  }

  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }
  const InvoiceGets = (id: any) => {
    InvoiceGet(id, BaseRequest, onGetSuccess, onFailed, 'GET')
  }
  const onGetSuccess = (res: any) => {
    setInvoiceData(res.data)
  }

  const defaultRowFormat = (cell: any, row: any) => {
    return <div className='text-center'>{cell}</div>
  }

  const addressRowFormat = (cell: any, row: any) => {
    return <div className='text-center w-300px'>{cell}</div>
  }

  const nameRowFormat = (cell: string, row: any) => {
    return (
      <Link to={'/user/' + row.uid} className='cursor-pointer text-center w-fit flex flex-col'>
        <span>
          {row.lastname.substr(0, 1)}.{cell}
        </span>
        <span>{row.albantushaal}</span>
      </Link>
    )
  }

  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div style={{minWidth: '130px'}} className='text-center'>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            InvoiceGets(row.invoice_id)
            setItem(row)
            setShowGereeModal(true)
          }}
        >
          <KTIcon iconName='switch' className='fs-3' />
        </div>

        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }

  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  deleteFunc(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const deleteFunc = (row: any) => {
    GereeDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }

  const onDeleteSuccess = () => {
    list()
    return toast.error(t('common:successDelete'))
  }
  const clearFilter = () => {
    setRd('')
  }

  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: 'Org RD',
        dataField: 'org_rd',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Phone',
        dataField: 'phone',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },

      {
        title: 'Address',
        dataField: 'address',
        dataSort: false,
        dataFormat: addressRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Signed',
        dataField: 'firstname',
        dataSort: false,
        dataFormat: nameRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },

      {
        title: t('common:employee.created_at'),
        dataField: 'created_at',
        dataSort: false,
        dataFormat: dateFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:employee.action'),
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>Гэрээ</PageTitle>
      {showGereeModal ? (
        <CustomModal
          visible={showGereeModal}
          closeModal={() => setShowGereeModal(false)}
          className='VideoModal'
        >
          <ContractPage invoiceData={invoiceData} item={item} close={setShowGereeModal} />
        </CustomModal>
      ) : null}

      {type === 'component' ? null : (
        <div className={`card mb-5`}>
          <div className='card-header border-1' style={{height: '60px'}}>
            <div
              className='filter flex items-center justify-center gap-0'
              onClick={() => setShowFilter(!showFilter)}
            >
              <KTIcon iconName='filter' className='fs-3' />
              <span className='text'>{t('common:filter')}</span>
            </div>
            <div className='card-toolbar'>
              <div onClick={list} className='btn btn-sm btn-light-primary'>{t('common:search')}</div>
            </div>
          </div>
          {showFilter ? (
            <div className='card-body py-3'>
              <div className='row flex items-center filter-wrap'>
                <div className='col'>
                  <input
                    onChange={(e) => setRd(e.target.value)}
                    value={rd}
                    type='text'
                    placeholder='RD'
                    className='form-control form-control-solid form-control-sm'
                  />
                </div>

                <div className='col'>
                  <div
                    onClick={() => clearFilter()}
                    className='btn btn-icon btn-color-primary btn-active-light-primary'
                  >
                    {t('common:USERS:clear')}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}

      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Гэрээ жагсаалт</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Нийт {PTotal} гэрээ</span>
          </h3>
        </div>

        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
        </div>
      </div>
    </>
  )
}

export {ContractListPage}
