import React, {FC, useEffect, useState} from 'react'
import {isNullOrEmpty} from '../../../services/Utils'
import {
  CheckRdtoName,
  DesignerConnectedOrg,
  DesignerUpdateOrg,
} from '../../../services/main'
import {toast} from 'react-toastify'
import {
  BaseRequest,
  DesignerConnectedOrgReq,
  DesignerUpdateOrgReq,
} from '../../../services/Request'
import {Link} from 'react-router-dom'

interface IProps {
  t: any
  item: any
  setcustomerAddModal: any
  listDes: any
}
const PutCustomer: FC<IProps> = ({t, item, setcustomerAddModal, listDes}) => {
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [loadingCheck, setLaodingCheck] = useState(false)
  const [uid, setUid] = useState('')
  const [rd, setRd] = useState('')
  const [name, setName] = useState('')
  const [prevName, setPrevName] = useState('')
  const [orglist, setOrglist] = useState([])
  const [validate, setValidate] = useState<any>([])

  useEffect(() => {
    setUid(item?.id)
    if (!isNullOrEmpty(item.org_rd)) {
      setLaodingCheck(true)
      CheckRdtoName(item.org_rd, BaseRequest, onCheckPrevSuccess, onFailed, 'GET')
    }
  }, [item])

  useEffect(() => {
    setName('')
    setOrglist([])
    if (rd.length > 6) {
      setLaodingCheck(true)
      CheckRdtoName(rd, BaseRequest, onCheckSuccess, onFailed, 'GET')
    }
  }, [rd])

  const add = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = DesignerUpdateOrgReq
      req.designer.id = uid
      req.designer.org_rd = rd
      DesignerUpdateOrg(req, addSuccess, onFailed, 'POST')
    }
  }
  const addSuccess = () => {
    setLaoding(false)
    setcustomerAddModal(false)
    listDes()
    return toast.success('Байгууллага амжилттай солигдлоо')
  }
  const list = () => {
    if (!loading) {
      setLaoding(true)
    }
    var req = DesignerConnectedOrgReq
    req.filter.org_rd = rd
    DesignerConnectedOrg(req, onSuccess, onFailed, 'POST')
  }
  const onSuccess = (response: any) => {
    setLaoding(false)
    setOrglist(response.data)
  }

  const onFailed = (err: string) => {
    setLaoding(false)
    setLaodingCheck(false)
    return toast.error(err)
  }

  const validateFunc = () => {
    var res = {retType: 0}

    if (isNullOrEmpty(uid)) {
      res.retType = 1
      validate['uid'] = true
    }

    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  const onCheckSuccess = (res: any) => {
    setLaodingCheck(false)
    setName(res.data.name)
    if (res.data.found) {
      list()
    }
  }
  const onCheckPrevSuccess = (res: any) => {
    setLaodingCheck(false)
    setPrevName(res.data.name)
  }
  return (
    <div>
      <div className='mx-5 mx-xl-10 pt-10 pb-10 px-16 modal-width'>
        <span style={{visibility: 'hidden'}}>{count}</span>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>Байгууллагад хэрэглэгч холбох</h1>
        </div>

        <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
            хэрэглэгчийн мэдээлэл
          </span>
        </div>
        {!isNullOrEmpty(item?.org_rd) ? (
          <div className='mb-4'>
            <input
              className='form-control form-control-solid w-300px'
              placeholder={t('common:USERS.uid')}
              value={item?.org_rd}
              disabled={true}
            />
          </div>
        ) : null}

        {!isNullOrEmpty(prevName) && !loadingCheck ? (
          <div className='mb-4'>
            <input
              className='form-control form-control-solid w-300px'
              value={prevName}
              disabled={true}
            />
          </div>
        ) : null}

        <div className='mb-4'>
          <input
            className='form-control form-control-solid w-300px'
            placeholder={t('common:USERS.uid')}
            value={uid}
            disabled={true}
          />
        </div>
        <div className='mb-4'>
          <input
            className='form-control form-control-solid w-300px'
            placeholder={t('common:USERS.uid')}
            value={item?.name}
            disabled={true}
          />
        </div>
        <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
            байгууллагын мэдээлэл
          </span>
        </div>
        <div className='mb-4'>
          <input
            className='form-control form-control-solid w-300px'
            placeholder='Харилцагчын регистр'
            disabled={loadingCheck}
            onChange={(e) => {
              setRd(e.target.value)
            }}
            value={loadingCheck ? `${rd} Уншиж байна...` : rd}
            type='text'
            maxLength={7}
          />
          
        </div>

        <div className='mb-4'>
          <input
            className='form-control form-control-solid w-300px'
            value={name}
            type='text'
            disabled={true}
          />
          
        </div>

        {orglist.length > 0 ? (
          <>
            <div className='separator mt-8 d-flex flex-center mb-8'>
              <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
                Уг байгууллагын хэрэглэгчид
              </span>
            </div>
            <div className='flex flex-col gap-1 '>
              {orglist.map((uitem: any, index) => {
                return (
                  <Link to={`/user/${uitem.id}`} key={index}>
                    {index + 1}. {uitem?.name}
                  </Link>
                )
              })}
            </div>
          </>
        ) : null}
        <div className='pt-10 flex gap-4' style={{justifyContent: 'end'}}>
          <button
            className='btn btn-secondary align-self-center'
            onClick={() => setcustomerAddModal(false)}
            disabled={loading}
          >
            {loading ? t('common:loading') : t('common:cancel')}
          </button>
          <button className='btn btn-primary align-self-center' onClick={add} disabled={loading}>
            {loading ? t('common:loading') : t('common:confirm')}
          </button>
        </div>
      </div>
    </div>
  )
}

export {PutCustomer}
