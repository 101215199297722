import React, { useEffect, useRef, useState } from "react";
import { QRCode } from "react-qrcode-logo";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { iNVOICEMULTIPLIER, SALE, SALE2 } from "../../../services/main";
import { dateFormat, isNullOrEmpty, moneyFormat } from "../../../services/Utils";
import NumberToMongolian from "../../../_metronic/layout/components/numbertoString";

function InvoicePdf({ invoiceData, setQpayModal }) {
  const nehemjlel = useRef(null);
  const [addedDate, setAddedDate] = useState("");
  const [discount, setDiscount] = useState("");
  const nehemlehDay = 5;

  useEffect(() => {
    const result = new Date(invoiceData?.created_at);
    const final = new Date(result.setDate(result.getDate() + nehemlehDay));
    setAddedDate(formatDate(final));
  }, [invoiceData]);

  useEffect(() => {
    if (invoiceData?.month < 6) {
      setDiscount(0);
    } else if (invoiceData?.month >= 6 && invoiceData?.month <= 11) {
      setDiscount(SALE2);
    } else {
      setDiscount(SALE);
    }
  }, [invoiceData]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are 0-based
    const day = date.getDate();
    return `${year}.${month}.${day}`;
  };

  const handleDownloadPdf = async (id, nehemjlel) => {
    try {
      const element = nehemjlel.current;
      if (!element) {
        throw new Error("No element found to render PDF.");
      }

      const images = element.querySelectorAll("img");
      const imagePromises = Array.from(images).map((img) => {
        return new Promise((resolve, reject) => {
          if (img.complete) {
            resolve();
          } else {
            img.onload = () => resolve();
            img.onerror = () =>
              reject(new Error(`Failed to load image: ${img.src}`));
          }
        });
      });

      await Promise.all(imagePromises);

      const canvas = await html2canvas(element, { scale: 2, useCORS: true });
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`grado-nehemjeh-${id * iNVOICEMULTIPLIER}.pdf`);
    } catch (error) {
      console.error("Error creating PDF:", error);
    }
  };
  return (
    <div className="overflow-x-auto">
      <div
        style={{
          padding: "30px",
          width: "960px",
          maxWidth: "1024px",
          overflowX: "scroll",
        }}
      >
        <div
          className="flex gap-3 w-full justify-center bg-N80 p-3 text-sm font-semibold z-1"
          style={{ position: "absolute", top: "0", left: "0" }}
        >
          <button
            className="btn btn-primary px-3 py-2 rounded-lg mobile:px-2 mobile:py-2 text-md mobile:text-sm flex gap-1"
            onClick={() => handleDownloadPdf(invoiceData?.id, nehemjlel)}
          >
            {/* <DownloadSimple size={18}/> */}
            Нэхэмжлэл татах
          </button>
          {/* {!isNullOrEmpty(setQpayModal) && invoiceData?.status === 0 ? (
            <button
              className="btn-primary px-3 py-2 rounded-lg mobile:px-2 mobile:py-2 text-md mobile:text-sm flex gap-1 items-center"
              style={{
                backgroundColor: "#3498db",
                border: "none",
                color: "white",
              }}
              onClick={() => {
                setQpayModal(true);
              }}
            >
              <img width={30} height={30} src={"/images/qpay.webp"} />
              Төлбөр төлөх
            </button>
          ) : null} */}
        </div>
        <div style={{ position: "absolute", top: "90px",fontWeight:"600", right: "10px", rotate:"25deg",border:"3px solid grey", fontSize:"20px",padding:"2px 4px", zIndex:"2" }}>
          {invoiceData?.status === 0 ? (
            <div
              style={{ borderColor: "grey", color: "grey" }}
            >
              Төлөгдөөгүй
            </div>
          ) : invoiceData?.status === 6 ? (
            <div>Цуцлагдсан</div>
          ) : (
            <div
              style={{ border: "green 4px solid", color: "green", padding:"4px 12px" }}
            >
              Төлөгдсөн
            </div>
          )}
        </div>
        <div
          style={{ padding: "48px 24px", position: "relative", backgroundColor:"white" }}
          ref={nehemjlel}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <img width={150} src={"/logo.png"} />
            <span style={{ fontSize: "24px", fontWeight: "600" }}>
              Нэхэмжлэх №: {invoiceData?.skey?.charAt(0).toUpperCase()}-{invoiceData?.id * iNVOICEMULTIPLIER}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "36px",
              fontSize: "14px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span className="font-bold">Нэхэмжлэгч:</span>

              <span>Регистрийн дугаар: 6942326</span>
              <span>Нэр: Нова хекс</span>
              <span style={{maxWidth:"350px"}}>
                Хаяг: Улаанбаатар хот, Сvхбаатар дvvрэг, Бага тойруу-49,
                Мэдээлэл технологийн парк, 1 давхар 108 тоот
              </span>

              <span></span>
              <span>Утас: 77779969</span>
              <span>Э-Шуудан: info@grado.mn</span>
              <span>Банкны нэр: МБанк</span>
              <span>Дансны дугаар: 9006942326 /төгрөгийн/</span>
              <span>
                Гүйлгээний утга:{" "}
                <span className="font-semibold">
                {invoiceData?.skey?.charAt(0).toUpperCase()}-{invoiceData?.id * iNVOICEMULTIPLIER}
                </span>
              </span>
            </div>

            <div style={{ display: "flex",maxWidth:"350px", flexDirection: "column" }}>
              <span className="font-bold">Хариуцагч:</span>
              {!isNullOrEmpty(invoiceData?.org_rd) ? (
                <span>Регистрийн дугаар: {invoiceData?.org_rd}</span>
              ) : (
                <span>Email хаяг: {invoiceData?.email}</span>
              )}

              <span style={{ maxWidth: "300px" }}>
                Худалдан авагчийн нэр:{" "}
                {!isNullOrEmpty(invoiceData?.org_rd)
                  ? invoiceData?.org_name
                  : invoiceData?.uname}
              </span>

              <span>
                Нэхэмжилсэн огноо: {dateFormat(invoiceData?.created_at)}
              </span>
              <span>Төлбөр хийх хугацаа: {addedDate}</span>
            </div>
          </div>

          <div>
            <table style={{fontSize:"14px", fontWeight:"400"}} className="w-100  mt-5">
              <thead>
                <tr
                  style={{
                    borderBottom: "1px solid rgba(0,0,0,0.5)",
                    height: "40px",
                  }}
                >
                  <th>№</th>
                  <th>
                    <h2 style={{fontSize:"14px"}} className="w-full text-center">Барааны нэр</h2>
                  </th>
                  <th>
                    <h2 style={{fontSize:"14px"}} className="w-full text-right">Нэгж үнэ</h2>
                  </th>
                  <th>
                    <h2 style={{fontSize:"14px"}} className="w-full text-right">Хугацаа</h2>
                  </th>
                  <th>
                    <h2 style={{fontSize:"14px"}} className="w-full text-right">Нийт үнэ</h2>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td className="font-semibold ">1</td>
                  <td className="pb-3">
                    <div className="flex flex-col justify-center items-center">
                      <span className="font-semibold w-full text-center">
                        {invoiceData?.subscription === "sub1"
                          ? invoiceData?.skey === "grado"
                            ? "Стандарт"
                            : "Багц 1"
                          : invoiceData?.subscription === "sub2"
                          ? invoiceData?.skey === "grado"
                            ? "Байгууллага"
                            : "Багц 2"
                          : invoiceData?.subscription === "sub3"
                          ? invoiceData?.skey === "grado"
                            ? "Боловсрол"
                            : "Багц 3"
                          : invoiceData?.subscription === "super"
                          ? "Супер"
                          : ""}
                      </span>
                      <a
                        target="_blank"
                        href={
                          invoiceData?.skey === "tool"
                            ? "https://tool.grado.mn"
                            : invoiceData?.skey === "grado"
                            ? "https://grado.mn"
                            : invoiceData?.skey === "qr"
                            ? "https://qr.grado.mn"
                            : invoiceData?.skey === "flipbook"
                            ? "https://flipbook.grado.mn"
                            : "https://banner.grado.mn"
                        }
                        className="text-primary font-semibold"
                      >
                        {invoiceData?.skey === "tool"
                          ? "tool.grado.mn"
                          : invoiceData?.skey === "grado"
                          ? "grado.mn"
                          : invoiceData?.skey === "qr"
                          ? "qr.grado.mn"
                          : invoiceData?.skey === "flipbook"
                          ? "flipbook.grado.mn"
                          : "banner.grado.mn"}
                      </a>
                    </div>
                  </td>
                  <td>
                    <div className=" flex gap-2 ">
                      <span className="w-full text-right">
                        {moneyFormat(
                          invoiceData?.price / ((100 - discount) / 100),
                          "MNT"
                        )}
                      </span>
                    </div>
                  </td>
                  <td>
                    <span className="w-full text-right">
                      {invoiceData?.month} сар
                    </span>
                  </td>
                  <td>
                    <h1 style={{fontWeight:"600", textAlign:"end", fontSize:"14px"}}>
                      {moneyFormat(
                        (invoiceData?.price / ((100 - discount) / 100)) *
                          invoiceData?.month,
                        "MNT"
                      )}
                    </h1>
                  </td>
                </tr>
                <tr style={{ borderTop: "1px solid black", height: "40px" }}>
                  <td colSpan={4}>
                    <h2 className="w-full text-end" style={{fontSize:"16px"}}>
                      <span className="text-sm">Хөнгөлөлт {discount}% :</span>
                    </h2>
                  </td>
                  <td>
                    <h2 style={{fontSize:"16px", textAlign:"end"}} className="font-semibold w-full text-right">
                      {discount === 0
                        ? "0₮"
                        : moneyFormat(
                            (invoiceData?.price / ((100 - discount) / 100)) *
                              invoiceData?.month *
                              (discount / 100),
                            "MNT"
                          )}
                    </h2>
                  </td>
                </tr>
                <tr style={{ height: "40px" }}>
                  <td colSpan={4}>
                    <h2 style={{fontSize:"16px"}} className="w-full text-end text-sm">
                      Нийт /НӨАТ-тай/ :{" "}
                    </h2>
                  </td>
                  <td style={{ borderTop: "1px solid black" }}>
                    <h2 style={{fontSize:"16px", textAlign:"end"}} className="font-bold text-lg w-full text-right">
                      {moneyFormat(
                        invoiceData?.price * invoiceData?.month,
                        "MNT"
                      )}
                    </h2>{" "}
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{justifyContent:"flex-end"}} className="flex">
             <div style={{width:"fit-content"}} className="flex gap-1 mt-2">
              /<p>Мөнгөн дүн: (үсгээр) </p>
              {!isNullOrEmpty(invoiceData?.total_price) ? (
                <NumberToMongolian number={invoiceData?.total_price} />
              ) : null}-н
              <span className="">төгрөг</span> /
            </div> 
            </div>
            

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "60px",
              }}
            >
              <div className="flex flex-col gap-1 items-center">
                <p className="font-semibold">Төлбөр төлөх</p>
                <QRCode
                  value={`https://grado.mn/invoice/${invoiceData?.id * iNVOICEMULTIPLIER}`}
                  size="180"
                  ecLevel="Q"
                />
                <a
                  target="_blank"
                  className="text-xs text-flipbook"
                  href={`https://grado.mn/invoice/${invoiceData?.id * iNVOICEMULTIPLIER}`}
                >{`https://grado.mn/invoice/${invoiceData?.id * iNVOICEMULTIPLIER}`}</a>
              </div>

              <div className="flex flex-col gap-12">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    fontSize: "14px",
                  }}
                >
                  <span>
                    Захирал: ...................................... /
                    ..................................... /
                  </span>
                  <span>
                    Нягтлан бодогч: ..................................... /
                    ...................................... /
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <span>/Тамга/</span>
                  <img
                    width={220}
                    height={150}
                    src="/images/stamp.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoicePdf;
