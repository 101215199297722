import React, {FC, useEffect, useState} from 'react'
import {isNullOrEmpty} from '../../../services/Utils'
import {CheckRdtoName, CustomerAdd, EmployeeAdd, EmployeeChangeInfo} from '../../../services/main'
import {toast} from 'react-toastify'
import {
  BaseRequest,
  CustomerAddReq,
  EmployeeAddReq,
  EmployeeChangeInfoReq,
  magicnum,
} from '../../../services/Request'
import useCookie from '../../../services/useCookie'
interface IProps {
  t: any
  item: any
  onAddSuccess: any
}
const EmployeeCreate: FC<IProps> = ({t, item, onAddSuccess}) => {
  const [rd, setRd] = useState(isNullOrEmpty(item) ? '' : item.rd)
  const [name, setName] = useState(isNullOrEmpty(item) ? '' : item.name)
  const [logo, setLogo] = React.useState<any>('')
  const [type, setType] = useState(isNullOrEmpty(item) ? '' : item.type)
  const [address_city, setAddress_city] = useState(isNullOrEmpty(item) ? '' : item.address_city)
  const [address_district, setAddress_District] = useState(
    isNullOrEmpty(item) ? 'super' : item.address_district
  )
  const [address, setAddress] = useState(isNullOrEmpty(item) ? '' : item.address)
  const [albantushaal, setAlbantushaal] = useState(isNullOrEmpty(item) ? '' : item.albantushaal)
  const [mobile, setMobile] = useState(isNullOrEmpty(item) ? '' : item.mobile)
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [loadingImageData, setLaodingImageData] = useState(false)
  const [validate, setValidate] = useState<any>([])
  const [noc, setNoc] = useState('')
  const [EmployeeID, updateEmployeeID] = useCookie('GAEmployeeID', null)

  useEffect(() => {
    if (rd.length > 6) {
      CheckRdtoName(rd, BaseRequest, onCheckSuccess, onFailed, 'GET')
    }
  }, [rd])


  const save = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = CustomerAddReq
      req.customer.rd = rd
      req.customer.name = name
      req.customer.type = type
      req.customer.address_city = JSON.parse(address_city)?.id.toString()
      req.customer.address_district = address_district
      req.customer.address = address
      req.customer.albantushaal = albantushaal
      req.customer.utas = mobile
      req.customer.uid = EmployeeID
      CustomerAdd(req, onSuccess, onFailed, 'POST')
    } else {
      return toast.warning(t('common:validForm'))
    }
  }

  const handleSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLaodingImageData(true)
    if (event.target.files) {
      var file = event.target.files[0]
      var reader = new FileReader()

      reader.onload = function (event) {
        // setImageBase64(event?.target?.result)
        setLaodingImageData(false)
      }

      reader.readAsDataURL(file)
    }
  }

  const onSuccess = () => {
    setLaoding(false)
    onAddSuccess(t('common:successCreate'))
  }

  const onFailed = (err: string) => {
    setLaoding(false)
    return toast.error(err)
  }

  const update = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLaoding(true)
      var req = EmployeeChangeInfoReq
      // req.Username = email
      // req.FirstName = firstname
      // req.LastName = lastname
      // req.Status = status
      // req.TypeID = typeid
      req.Noc = noc
      req.id = item.id
      EmployeeChangeInfo(req, onUpdateSuccess, onFailed, 'PUT')
    }
  }

  const onUpdateSuccess = () => {
    setLaoding(false)
    onAddSuccess(t('common:successUpdate'))
  }

  const validateFunc = () => {
    var res = {retType: 0}

    if (isNullOrEmpty(rd)) {
      res.retType = 1
      validate['rd'] = true
    }

    if (isNullOrEmpty(name)) {
      res.retType = 1
      validate['name'] = true
    }

    if (isNullOrEmpty(type)) {
      res.retType = 1
      validate['type'] = true
    }

    if (isNullOrEmpty(address)) {
      res.retType = 1
      validate['address'] = true
    }

    if (isNullOrEmpty(address_city)) {
      res.retType = 1
      validate['address_city'] = true
    }
    if (isNullOrEmpty(address_district)) {
      res.retType = 1
      validate['address_district'] = true
    }
    if (isNullOrEmpty(albantushaal)) {
      res.retType = 1
      validate['albantushaal'] = true
    }
    if (isNullOrEmpty(mobile)) {
      res.retType = 1
      validate['mobile'] = true
    }
    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const validateChange = (id: string, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  const onCheckSuccess = (res: any) => {
    setName(res.data.name)
  }

  return (
    <div>
      <div className='scroll-x mx-5 mx-xl-10 pt-10 pb-10 w-500px'>
        <span style={{visibility: 'hidden'}}>{count}</span>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>Харилцагч нэмэх</h1>
        </div>
        {isNullOrEmpty(item) ? (
          <div className='mb-8'>
            <div className='btn btn-light-primary fw-bolder w-100'>
              <label htmlFor='files'>{loadingImageData ? t('common:loading') : 'Лого'}</label>
            </div>
          </div>
        ) : null}

        <input
          type='file'
          name='image'
          id='files'
          style={{display: 'none'}}
          accept='.pdf,.jpg,.png,.jpeg'
          onChange={handleSelectFile}
        />
        {isNullOrEmpty(logo) ? null : (
          <div className='d-flex flex-center'>
            <img src={logo} width={200} height={100} />
          </div>
        )}
        <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>text</span>
        </div>
        <div className='mb-8'>
          <input
            className='form-control form-control-solid'
            placeholder='Регистр'
            type='text'
            maxLength={7}
            onChange={(e) => {
              setRd(e.target.value)
              validateChange('rd', e)
            }}
            value={rd}
          ></input>
          {validate['rd'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>
        <div className='mb-8'>
          <input
            className='form-control form-control-solid'
            placeholder='Нэр'
            onChange={(e) => {
              setName(e.target.value)
              validateChange('name', e)
            }}
            value={name}
          ></input>
          {validate['name'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>

        <div className='mb-8'>
          <select
            name='aimag'
            data-control='select2'
            data-hide-search='true'
            value={type}
            className='form-select form-select-white form-select-sm'
            onChange={(e) => {
              setType(e.target.value)
              validateChange('type', e)
            }}
          >
            <option value=''>Төрөл сонгох</option>
            <option value='edu'>Сургууль</option>
            <option value='sob'>Цэцэрлэг</option>
            <option value='org'>Байгууллага</option>
            <option value='tor'>Төрийн Байгууллага</option>
          </select>
        </div>

        <div className='mb-8'>
          <input
            className='form-control form-control-solid'
            placeholder='address'
            onChange={(e) => {
              setAddress(e.target.value)
              validateChange('address', e)
            }}
            value={address}
          ></input>
          {validate['address'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>

        <div className='mb-8'>
          <select
            name='aimag'
            data-control='select2'
            data-hide-search='true'
            value={address_city}
            className='form-select form-select-white form-select-sm'
            onChange={(e) => {
              setAddress_city(e.target.value)
              validateChange('address_city', e)
            }}
          >
            <option value=''>аймаг сонгох</option>
            {magicnum.address_city.map((el, index) => {
              return (
                <option value={JSON.stringify(el)} key={index}>
                  {el.name}
                </option>
              )
            })}
          </select>
          {validate['address_city'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validSelect')}</div>
            </div>
          ) : null}
        </div>

        <div className='mb-8'>
          <select
            name=''
            data-control='select2'
            data-hide-search='true'
            value={address_district}
            className='form-select form-select-white form-select-sm'
            onChange={(e) => {
              setAddress_District(e.target.value)
              validateChange('address_district', e)
            }}
          >
            <option value=''>сум сонгох</option>
            {isNullOrEmpty(address_city)
              ? null
              : magicnum.address_district
                  .filter((l) => l.city_id == JSON.parse(address_city)?.id)
                  .map((el, index) => {
                    return (
                      <option value={el.id} key={index}>
                        {el.name}
                      </option>
                    )
                  })}
          </select>
          {validate['address_district'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validSelect')}</div>
            </div>
          ) : null}
        </div>

        <div className='mb-8'>
          <input
            className='form-control form-control-solid'
            placeholder='Албан тушаал'
            onChange={(e) => {
              setAlbantushaal(e.target.value)
              validateChange('rd', e)
            }}
            value={albantushaal}
          ></input>
          {validate['albantushaal'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>

        <div className='mb-8'>
          <input
            className='form-control form-control-solid'
            placeholder='Утас'
            onChange={(e) => {
              setMobile(e.target.value)
              validateChange('rd', e)
            }}
            value={mobile}
          ></input>
          {validate['mobile'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>

        <div className='pt-10' style={{textAlign: 'right'}}>
          <button
            className='btn btn-primary align-self-center'
            onClick={isNullOrEmpty(item) ? save : update}
            disabled={loading}
          >
            {loading ? t('common:loading') : t('common:save')}
          </button>
        </div>
      </div>
    </div>
  )
}

export {EmployeeCreate}
