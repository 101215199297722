import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import CustomModal from '../../modules/CustomModal/modal'
import {dateFormat, dateTimeFormat, isNullOrEmpty, isValidEmail} from '../../../services/Utils'
import {BaseRequest, magicnum} from '../../../services/Request'
import {CustomerGet} from '../../../services/main'
import {toast} from 'react-toastify'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router-dom'
import clsx from 'clsx'
import {Subscription} from '../subscription/index'
import {LetterBannerLast} from '../letterbanner/last'
import {Feedback} from '../feedback'
import {UserCreated} from '../template/user_created'
import {Qr} from '../qr'
import {Event} from '../event'
import {Flipbook} from '../flipbook'
import {SearchPage} from '../search'
import {InvoiceListPage} from './invoice'
import {ContractListPage} from './contract'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Customer',
    path: '/customer',
    isSeparator: false,
    isActive: false,
  },
]

type Props = {
  type?: any
}

const CustomerMore: FC<Props> = () => {
  const {id} = useParams()
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>('')
  const [tab, setTab] = useState('contract')
  const [showModal, setShowModal] = useState<boolean>(false)
  const [validate, setValidate] = useState<any>([])
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [pNumber, setPNumber] = useState('')
  const [provider, setProvider] = useState('')
  const [isDesigner, setIsDesigner] = useState('')

  useEffect(() => {
    get()
  }, [id])

  const get = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = BaseRequest
    CustomerGet(id, req, onGetSuccess, onFailed, 'GET')
  }

  const onGetSuccess = (response: any) => {
    setLoading(false)
    setData(response?.data)
  }

  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  
  return (
    <>
      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => setShowModal(false)}
          className='VideoModal'
        >
          <div className='mx-5 mx-xl-10 pt-10 pb-10 w-500px'>
            <span style={{visibility: 'hidden'}}></span>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>{t('common:USERS.activate')}</h1>
            </div>

            <div className='separator mt-8 d-flex flex-center mb-8'>
              <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
                {t('common:USERS.activate')}
              </span>
            </div>
            <div className='mb-4'>
              <input
                className='form-control form-control-solid'
                placeholder='name'
                onChange={(e) => {
                  setName(e.target.value)
                }}
                value={name}
              ></input>
              
            </div>
            <div className='mb-4'>
              <input
                className='form-control form-control-solid'
                value={email}
                placeholder='email'
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              ></input>
             
            </div>
            <div className='mb-4'>
              <input
                type='number'
                className='form-control form-control-solid'
                placeholder='mobile'
                onChange={(e) => {
                  setPNumber(e.target.value)
                }}
                value={pNumber}
              ></input>
              
            </div>

            <div className='mb-4'>
              <select
                onChange={(e) => {
                  setProvider(e.target.value)
                }}
                className='form-select form-select-sm form-select-solid'
                name='provider'
                id=''
                value={provider}
              >
                <option value=''>{t('common:validSelect')}</option>
                <option value='google'>{t('common:USERS.google')}</option>
                <option value='facebook'>{t('common:USERS.facebook')}</option>
                <option value='grado'>{t('common:USERS.grado')}</option>
                <option value='web'>{t('common:USERS.web')}</option>
                <option value='admin'>{t('common:USERS.admin')}</option>
              </select>
              
            </div>
            <div className='mb-4'>
              <select
                onChange={(e) => {
                  setIsDesigner(e.target.value)
                }}
                className='form-select form-select-sm form-select-solid'
                name='status'
                value={isDesigner}
                id=''
              >
                <option value={''}>{t('common:validSelect')}</option>

                <option value={'1'}>{t('common:USERS.designer')}</option>
                <option value={'0'}>{t('common:USERS.notDesigner')}</option>
              </select>
              {validate['isDesigner'] ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{t('common:validInsert')}</div>
                </div>
              ) : null}
            </div>

            <div className='pt-10' style={{textAlign: 'right'}}>
              <button
                className='btn btn-primary align-self-center'
                // onClick={isNullOrEmpty(data) ? save : update}
                disabled={loading}
              >
                {loading ? t('common:loading') : t('common:save')}
              </button>
            </div>
          </div>
        </CustomModal>
      ) : null}

      <PageTitle breadcrumbs={dashboardBreadCrumbs}>{data.name}</PageTitle>
      <div className={`card mb-5`}>
        <div className='card-header card-header-overflow border-1'>
          <div className='flex gap-8 col-lg-3 col-form-label w-full card-header-flex'>
            <div className='zurag py-8'>
              {isNullOrEmpty(data.image) ? (
                <div
                  className='no-zurag'
                  style={{
                    width: '120px',
                    height: '150px',
                    borderRadius: '10px',
                    backgroundColor: '#ecf0f1',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  No Image
                </div>
              ) : (
                <img
                  src={data.image}
                  style={{borderRadius: '15px'}}
                  width={120}
                  height={150}
                  alt=''
                />
              )}
            </div>
            <div className='flex flex-col gap-4 p-8 infos'>
              {!isNullOrEmpty(data.name) ? (
                <div className='w-200px flex gap-3 items-center'>
                  <KTIcon iconName='user' iconType='outline' className='fs-2' />
                  <span>{data.name}</span>
                </div>
              ) : null}
              {!isNullOrEmpty(data.rd) ? (
                <div className='w-200px flex gap-3 items-center'>
                  <KTIcon iconName='user' iconType='outline' className='fs-2' />
                  <span>{data.rd}</span>
                </div>
              ) : null}
              {!isNullOrEmpty(data.type) ? (
                <div className='flex gap-3 items-center'>
                  <KTIcon iconName='barcode' iconType='outline' className='fs-2' />
                  <span>{data.type}</span>
                </div>
              ) : null}

              {!isNullOrEmpty(data.utas) ? (
                <div className='flex gap-3 items-center'>
                  <KTIcon iconName='phone' iconType='outline' className='fs-2' />
                  <span>{data.utas}</span>
                </div>
              ) : null}
              {!isNullOrEmpty(data.address_city) ? (
                <div className='flex gap-3 items-center'>
                  <KTIcon iconName='social-media' iconType='outline' className='fs-2' />
                  <span>
                    {
                      magicnum.address_city.find(
                        (el: any) => el.id === parseInt(data?.address_city)
                      )?.name
                    }
                  </span>
                  <span>
                    {
                      magicnum.address_district.find(
                        (el) =>
                          el.city_id === parseInt(data?.address_city) &&
                          el.id === parseInt(data?.address_district)
                      )?.name
                    }
                  </span>
                </div>
              ) : null}
            </div>
            <div className='flex flex-col gap-4 p-8 infos'>
              {!isNullOrEmpty(data.address) ? (
                <div className='flex gap-3 items-center'>
                  <KTIcon iconName='phone' iconType='outline' className='fs-2' />
                  <span>{data.address}</span>
                </div>
              ) : null}
              {!isNullOrEmpty(data.is_designer) ? (
                <div className='w-250px flex gap-3 items-center'>
                  <KTIcon iconName='design' iconType='outline' className='fs-2' />
                  <span>{data.is_designer == 1 ? 'Designer' : 'Not designer'}</span>
                </div>
              ) : null}
              {!isNullOrEmpty(data.created_at) ? (
                <div className=' flex gap-3 items-center'>
                  <KTIcon iconName='calendar-2' iconType='outline' className='fs-2' />
                  <span>{dateTimeFormat(data.created_at)}</span>
                </div>
              ) : null}
              {!isNullOrEmpty(data.bagts) ? (
                <div className='flex gap-3 items-center'>
                  <KTIcon iconName='dropbox' iconType='outline' className='fs-2' />
                  <span>{data.bagts}</span>
                </div>
              ) : null}
              {!isNullOrEmpty(data.bagts_hugatsaa) ? (
                <div className='flex gap-3 items-center'>
                  <KTIcon iconName='time' iconType='outline' className='fs-2' />
                  {isNullOrEmpty(data.bagts_hugatsaa) ? (
                    <span className='badge badge-light-warning'>{t('common:USERS:inActive')}</span>
                  ) : (
                    <>
                      <span className='badge badge-light-success'>{t('common:USERS:active')}</span>
                      <span>{dateFormat(data.bagts_hugatsaa)}</span>
                    </>
                  )}
                </div>
              ) : null}

              {/* <button onClick={() => setShowModal(true)} className='btn btn-primary'>
                activate
              </button> */}
            </div>
            <div className='flex flex-col gap-4 p-8 infos'></div>
          </div>
        </div>
      </div>
      <div className='card'>
        <div className='card-header card-header-stretch overflow-auto'>
          <ul
            className='nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
            role='tablist'
          >
            <li className='nav-item'>
              <div
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'contract',
                })}
                onClick={() => setTab('contract')}
                role='tab'
              >
                Гэрээ
              </div>
            </li>
            <li className='nav-item'>
              <div
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'invoice',
                })}
                onClick={() => setTab('invoice')}
                role='tab'
              >
                Нэхэмжлэл
              </div>
            </li>
            <li className='nav-item'>
              <div
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'subscription',
                })}
                onClick={() => setTab('subscription')}
                role='tab'
              >
                Subscription
              </div>
            </li>
            
          </ul>
        </div>
        <form className='form'>
          <div className='card-body py-6'>
            {tab === 'contract' ? (
              <ContractListPage type='component' id={id} org_rd={data?.rd} />
            ) : null}
            {tab === 'invoice' ? (
              <InvoiceListPage type='component' id={id} org_rd={data?.rd} />
            ) : null}
            {tab === 'subscription' && !isNullOrEmpty(data?.rd) ? (
              <Subscription type='component' crd={data?.rd} />
            ) : null}
          </div>
        </form>
      </div>
    </>
  )
}

export {CustomerMore}
