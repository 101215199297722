import React, {FC, useEffect, useState} from 'react'

import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {
  BaseRequest,
  InvoiceChangeStatusReq,
  InvoiceListReq,
} from '../../../services/Request'
import {
  InvoiceChangeStatus,
  InvoiceDelete,
  InvoiceGet,
  InvoiceList,
} from '../../../services/main'
import {dateFormat, isNullOrEmpty, moneyFormat} from '../../../services/Utils'
import {KTIcon} from '../../../_metronic/helpers'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
import CustomModal from '../../modules/CustomModal/modal'
import InvoicePdf from './invoicePdf'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]
type IProps = {
  id?: any
  type?: any
  uid?: any
  org_rd?: any
}
const InvoiceListPage: FC<IProps> = ({id, type, uid, org_rd}) => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [rd, setRd] = useState('')
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [showModal, updateShowModal] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [invoiceData, setInvoiceData] = useState([])
  const [status, setStatus] = useState('')

  useEffect(() => {
    list()
  }, [PID])

  const list = () => {
    setLoading(true)
    var req = InvoiceListReq
    req.filter.org_rd = !isNullOrEmpty(org_rd) ? org_rd : rd
    req.filter.status = status
    req.filter.subscription = ''
    req.pid = PID
    req.pSize = sizePerPage
    InvoiceList(req, onSuccess, onFailed, 'POST')
  }

  const onSuccess = (response: any) => {
    setPTotal(response.pagination.ptotal)
    setLoading(false)
    setData(response.data)
  }

  const onFailed = (err: any) => {
    setLoading(false)
    console.log(err)
  }

  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }
  const InvoiceGets = (id: any) => {
    InvoiceGet(id, BaseRequest, onGetSuccess, onFailed, 'GET')
  }
  const onGetSuccess = (res: any) => {
    setInvoiceData(res.data)
  }

  const defaultRowFormat = (cell: any, row: any) => {
    return <div className='text-center'>{cell}</div>
  }
  const priceRowFormat = (cell: any, row: any) => {
    return <div className='text-center'>{moneyFormat(cell, 'MNT')}</div>
  }

  const bagtsRowFormat = (cell: string, row: any) => {
    return (
      <div className='flex flex-col'>
        <span>{cell}</span>
        <span>
          {cell === 'grado' && row.subscription === 'sub1'
            ? 'Стандарт'
            : cell === 'grado' && row.subscription === 'sub2'
            ? 'Байгууллага'
            : cell === 'grado' && row.subscription === 'sub3'
            ? 'Боловсрол'
            : cell}
        </span>
        <span>{moneyFormat(row.price, 'MNT')}</span>
        <span>{row.month} сар</span>
      </div>
    )
  }

  const nameRowFormat = (cell: string, row: any) => {
    return (
      <div style={{maxWidth: '250px'}} className='flex flex-col'>
        <span>{cell}</span>
        <span>{row.org_rd}</span>
      </div>
    )
  }
  const dateRowFormat = (cell: any, row: any) => {
    return <div className='text-center'>{dateFormat(cell)}</div>
  }

  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div className='text-end'>
        <div
          onClick={() => {
            InvoiceGets(row.id)
            updateShowModal(true)
          }}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTIcon iconName='switch' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }

  const statusRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex flex-col gap-2'>
        <div className='text-center cursor-pointer' onClick={() => submit(row, 'changeStaus')}>
          {cell === 0 ? (
            <span className='badge badge-light-warning'>Төлөгдөөгүй</span>
          ) : (
            <span className='badge badge-light-success'>Төлөгдсөн</span>
          )}
        </div>
        <div
          style={{fontSize: '11px', color: '#3498db', fontWeight: '600'}}
          className='text-center'
        >
          {isNullOrEmpty(row.gereeid) ? 'Гэрээтэй' : ''}
        </div>
      </div>
    )
  }

  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type === 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  type === 'deleteMain' ? deleteFunc(row) : changeStatus(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const deleteFunc = (row: any) => {
    InvoiceDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }

  const onDeleteSuccess = () => {
    list()
    return toast.error(t('common:successDelete'))
  }

  const changeStatus = (row: any) => {
    var req = InvoiceChangeStatusReq
    req.invoice.id = row.id
    req.invoice.status = row.status === 1 ? '0' : '1'
    InvoiceChangeStatus(req, onChangeStatusSuccess, onFailed, 'PUT')
  }

  const onChangeStatusSuccess = () => {
    list()
    return toast.success(t('common:successUpdate'))
  }
  const clearFilter=()=>{
    setRd('')
    setStatus('')
  }

  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: 'Байгууллага',
        dataField: 'org_name',
        dataSort: false,
        dataFormat: nameRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Багц',
        dataField: 'skey',
        dataSort: false,
        dataFormat: bagtsRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },

      {
        title: 'Үнэ',
        dataField: 'total_price',
        dataSort: false,
        dataFormat: priceRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },

      {
        title: t('common:employee.status'),
        dataField: 'status',
        dataSort: false,
        dataFormat: statusRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Огноо',
        dataField: 'created_at',
        dataSort: false,
        dataFormat: dateRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },

      {
        title: '',
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>Нэхэмжлэл</PageTitle>
      {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => updateShowModal(false)}
          className='VideoModal'
        >
          <InvoicePdf invoiceData={invoiceData} setQpayModal={null} />
        </CustomModal>
      ) : null}
      {type === 'component' ? null : (
        <div className={`card mb-5`}>
          <div className='card-header border-1' style={{height: '60px'}}>
            <div
              className='filter flex items-center justify-center gap-0'
              onClick={() => setShowFilter(!showFilter)}
            >
              <KTIcon iconName='filter' className='fs-3' />
              <span className='text'>{t('common:filter')}</span>
            </div>
            <div className='card-toolbar'>
              <div onClick={list} className='btn btn-sm btn-light-primary'>
                {t('common:search')}
              </div>
            </div>
          </div>
          {showFilter ? (
            <div className='card-body py-3'>
              <div className='row flex items-center filter-wrap'>
                <div className='col'>
                  <input
                    onChange={(e) => setRd(e.target.value)}
                    value={rd}
                    type='text'
                    placeholder='RD'
                    className='form-control form-control-solid form-control-sm'
                  />
                </div>
                <div className='col'>
                  <select
                    onChange={(e) => {
                      setStatus(e.target.value)
                    }}
                    className='form-select form-select-sm form-select-solid'
                    name='status'
                    value={status}
                    id=''
                  >
                    <option value=''>{t('common:all')}</option>
                    <option value='1'>Төлөгдсөн</option>
                    <option value='0'>Төлөгдөөгүй</option>
                  </select>
                </div>

                <div className='col'>
                  <div
                    onClick={() => clearFilter()}
                    className='btn btn-icon btn-color-primary btn-active-light-primary'
                  >
                    {t('common:USERS:clear')}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}

      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Нэхэмжлэл жагсаалт</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Нийт {PTotal} нэхэмжлэл</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
        </div>
      </div>
    </>
  )
}

export {InvoiceListPage}
