import React, {FC, useEffect, useRef, useState} from 'react'
import html2canvas from 'html2canvas'
import useCookie from '../../../services/useCookie'
import {isNullOrEmpty, moneyFormat} from '../../../services/Utils'
import {BaseRequest, gereeAddReq} from '../../../services/Request'
import {CDN_URL, GereeAdd, GereeGet, iNVOICEMULTIPLIER} from '../../../services/main'
import NumberToMongolian from '../../../_metronic/layout/components/numbertoString'
import jsPDF from 'jspdf'

type IProps = {
  item?: any
  invoiceData?: any
  close?: any
}

const ContractPage: FC<IProps> = ({invoiceData, item, close}) => {
  const [albantushaal, setAlbantushaal] = useState('')
  const [firstname, setFirstName] = useState('')
  const [lastname, setLastName] = useState('')
  const [mobile, setMobile] = useState('')
  const [address, setAddress] = useState('')
  const sigPad = useRef(null)
  const [signatureURL, setSignatureURL] = useState(null)
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(0)
  const [validate, setValidate] = useState<any>([])
  const [data, setData] = useState<any>(undefined)
  const [DesignerID] = useCookie('DesignerID', null)
  const [amjilttai, setAmjilttai] = useState<any>(false)
  const [loadingDownload, setLoadingDownload] = useState<any>(false)
  const [showgeree, setShowgeree] = useState<any>(true)
  const [contractend, setContractend] = useState('')
  const [base64signature, setbase64signature] = useState('')

  useEffect(() => {
    if (!isNullOrEmpty(item.id)) {
      GereeGets(item.id)
    }
  }, [item])

  useEffect(() => {
    if (!isNullOrEmpty(data?.signature)) {
      convertImageToBase64(CDN_URL + data?.signature, function (base64Image: any) {
        setbase64signature(base64Image)
      })
    }
  }, [data])

  useEffect(() => {
    const date = new Date(invoiceData?.created_at)
    date.setMonth(date.getMonth() + invoiceData?.month)
    setContractend(date as any)
  }, [invoiceData])

  const saveContract = () => {
    var res = validateFunc()
    if (res.retType === 0) {
      setLoading(true)
      var req = gereeAddReq
      req.geree.firstname = firstname
      req.geree.org_rd = invoiceData?.org_rd
      req.geree.lastname = lastname
      req.geree.albantushaal = albantushaal
      req.geree.phone = mobile
      req.geree.address = address
      req.geree.invoice_id = invoiceData?.id
      req.geree.signature = signatureURL
      req.geree.uid = DesignerID
      GereeAdd(req, onSuccess, onFailed, 'POST')
    }
  }

  const onSuccess = (response: any) => {
    setLoading(false)
    setAmjilttai(true)
    setShowgeree(false)
  }

  const onFailed = (err: any) => {
    setLoading(false)
    console.log(err)
  }

  const GereeGets = (id: any) => {
    GereeGet(id, BaseRequest, onGetSuccess, onFailed, 'GET')
  }

  const onGetSuccess = (response: any) => {
    setData(response.data)
  }

  const validateFunc = () => {
    var res = {retType: 0}
    if (isNullOrEmpty(albantushaal)) {
      res.retType = 1
      validate['albantushaal'] = true
    }
    if (isNullOrEmpty(firstname)) {
      res.retType = 1
      validate['firstname'] = true
    }
    if (isNullOrEmpty(lastname)) {
      res.retType = 1
      validate['lastname'] = true
    }
    if (isNullOrEmpty(mobile)) {
      res.retType = 1
      validate['mobile'] = true
    }
    if (isNullOrEmpty(address)) {
      res.retType = 1
      validate['address'] = true
    }
    if (isNullOrEmpty(signatureURL)) {
      res.retType = 1
      validate['signature'] = true
    }

    setValidate(validate)
    setCount(count + 1)
    return res
  }

  const validateChange = (id: any, e: any) => {
    var val = ''
    e.target === undefined ? (val = e) : (val = e.target.value)
    if (val !== '') {
      validate[id] = false
    } else {
      validate[id] = true
    }
    setValidate(validate)
    setCount(count + 1)
  }

  function convertImageToBase64(url: any, callback: any) {
    const xhr = new XMLHttpRequest()
    xhr.onload = function () {
      const reader = new FileReader()
      reader.onloadend = function () {
        callback(reader.result) // The base64-encoded image
      }
      reader.readAsDataURL(xhr.response)
    }
    xhr.open('GET', url, true)
    xhr.responseType = 'blob'
    xhr.send()
  }

  useEffect(() => {
    if (loadingDownload) {
      generateMultiPagePDF()
    }
  }, [loadingDownload])

  const generateMultiPagePDF = () => {
    const element = document.getElementById('element-to-download')

    if (element) {
      html2canvas(element, {scale: 1}).then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        const pdf = new jsPDF('p', 'mm', 'a4')

        // PDF dimensions
        const pageWidth = pdf.internal.pageSize.getWidth()
        const pageHeight = pdf.internal.pageSize.getHeight()

        // Canvas dimensions
        const canvasWidth = canvas.width
        const canvasHeight = canvas.height

        // Scaling factor to fit the image width to the PDF page width
        const scaleRatio = pageWidth / canvasWidth

        // Scaled dimensions
        const scaledHeight = canvasHeight * scaleRatio

        let yPosition = 0

        while (yPosition < canvasHeight) {
          const remainingHeight = canvasHeight - yPosition
          const pageContentHeight = Math.min(pageHeight / scaleRatio, remainingHeight)

          // Create a temporary canvas to crop the portion for the current page
          const pageCanvas = document.createElement('canvas')
          pageCanvas.width = canvas.width
          pageCanvas.height = pageContentHeight

          const pageContext = pageCanvas.getContext('2d')
          if (pageContext) {
            pageContext.drawImage(
              canvas,
              0,
              yPosition, // Source start point
              canvas.width,
              pageContentHeight, // Source dimensions
              0,
              0, // Destination start point
              pageCanvas.width,
              pageCanvas.height // Destination dimensions
            )
          }

          // Add the cropped portion to the PDF
          const pageImgData = pageCanvas.toDataURL('image/png')
          pdf.addImage(pageImgData, 'PNG', 0, 0, pageWidth, pageContentHeight * scaleRatio)

          yPosition += pageContentHeight

          if (yPosition < canvasHeight) {
            pdf.addPage()
          }
        }

        pdf.save('grado.mn-' + invoiceData?.org_name + '.pdf')
        setLoadingDownload(false)
      })
    }
  }

  const formatDateToMongolian = (dateString: any) => {
    const dateObj = new Date(dateString)
    const year = dateObj.getFullYear()
    const month = dateObj.getMonth() + 1
    const day = dateObj.getDate()

    return `${year} оны ${month} сарын ${day} өдөр`
  }

  return (
    <div className='mx-[-40px]'>
      <div className='h-[90vh] overflow-y-auto'>
        {!isNullOrEmpty(data) ? (
          
            <button
              className='btn btn-primary btn-sm'
              onClick={() => setLoadingDownload(true)}
              disabled={loadingDownload}
              style={{position:"absolute", top:"20px"}}
            >
              <i className='fa-solid fa-download'></i>
              {loadingDownload ? <span>Татаж байна</span> : <span>Гэрээ татах</span>}
            </button>
        ) : null}

        <div
          id='element-to-download'
          style={{
            fontSize: '14px',
            padding: '60px 120px',
            backgroundColor: 'white',
            textAlign: 'justify',
            lineHeight: '140%',
          }}
          className='w-1200px'
        >
          <div
            style={{
              height: '1950px',
              paddingBottom: loadingDownload ? '200px' : '',
            }}
            className='flex flex-col justify-between'
          >
            <div style={{position: 'relative'}} className='flex justify-between mt-12'>
              <span
                style={{
                  position: 'absolute',
                  top: '0px',
                  fontWeight: 'bold',
                }}
                className=''
              >
                БАТЛАВ
              </span>

              <div className='myCompany mt-12 relative flex flex-col gap-1'>
                <span>“НОВА ХЕКС” ХХК</span>
                <span>ГҮЙЦЭТГЭХ ЗАХИРАЛ:</span>
                <span style={{textAlign: 'end', width: '300px'}} className='mt-4'>
                  Б. АЯТХААН
                </span>

                <img
                  className=''
                  style={{
                    objectFit: 'cover',
                    position: 'absolute',
                    top: '80px',
                  }}
                  width={180}
                  src='/images/signature.png'
                />
                <img
                  style={{
                    objectFit: 'cover',
                    position: 'absolute',
                    top: '-50px',
                    left: '120px',
                  }}
                  height={260}
                  width={260}
                  src='/images/novahex.png'
                />
              </div>
              <div style={{maxWidth: '500px'}} className='mt-12 flex flex-col gap-1'>
                <span>“{invoiceData?.org_name} ХХК”</span>
                <div className='flex gap-2'>
                  {!isNullOrEmpty(data) ? (
                    <span style={{textTransform: 'uppercase'}}>{data?.albantushaal}</span>
                  ) : (
                    <span>{!isNullOrEmpty(albantushaal) ? albantushaal : 'Албан тушаал'} </span>
                  )}
                </div>
                {!isNullOrEmpty(data) ? (
                  <p className='mt-4' style={{textTransform: 'uppercase'}}>
                    {' '}
                    /........................................./
                    <span>{data?.lastname?.substr(0, 1)}.</span>
                    <span>{data?.firstname}</span>{' '}
                  </p>
                ) : (
                  <p className='mt-3 '>
                    {isNullOrEmpty(lastname) ? '..' : lastname.substring(0, 1)}
                    {'.'}
                    {isNullOrEmpty(firstname) ? '....................' : firstname}{' '}
                    /................................/
                  </p>
                )}
              </div>
            </div>
            <div>
              <div>
                <h2
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '20px',
                    fontWeight: '600',
                  }}
                  className='text-center'
                >
                  <span>Үйлчилгээ үзүүлэх гэрээ</span>
                </h2>
                <div className='flex justify-between px-8 mt-8 mb-4'>
                  <h1 style={{fontSize: '20px', fontWeight: '400'}}>
                    {formatDateToMongolian(invoiceData?.created_at)}
                  </h1>
                  <h1 style={{fontSize: '20px', fontWeight: '400'}}>
                    № -{' '}
                    {(new Date().getFullYear() % 100) + '/' + invoiceData?.id * iNVOICEMULTIPLIER}
                  </h1>
                  <h1 style={{fontSize: '20px', fontWeight: '400'}}>Улаанбаатар хот</h1>
                </div>
              </div>

              <h1 style={{fontSize: '20px', fontWeight: '400'}}>
                Энэхүү Үйлчилгээ үзүүлэх гэрээ /цаашид "Гэрээ" гэх/-ийг нэг талаас 9011845071 тоот
                Улсын бүртгэлийн дугаартай, <span style={{fontWeight: '600'}}>6942326</span> тоот
                регистрийн дугаартай <span style={{fontWeight: '600'}}>“НОВА ХЕКС” ХХК</span>{' '}
                /цаашид "Үйлчилгээ үзүүлэгч" гэх/-ийг төлөөлж Гүйцэтгэх захирал албан тушаалтай{' '}
                <span style={{fontWeight: '600'}}>Батбаяр</span> овогтой{' '}
                <span style={{fontWeight: '600'}}>Аятхаан</span> <br />
                &nbsp; &nbsp; &nbsp; &nbsp; Нөгөө талаас{' '}
                <span style={{fontWeight: '600'}}>{invoiceData?.org_rd} </span>
                тоот регистрийн дугаартай{' '}
                <span style={{fontWeight: '600', textTransform: 'uppercase'}}>
                  "{invoiceData?.org_name}"
                </span>{' '}
                /цаашид "Үйлчилгээ авагч" гэх/-ийг төлөөлж{' '}
                {!isNullOrEmpty(data) ? data.albantushaal : albantushaal} албан тушаалтай{' '}
                {!isNullOrEmpty(data) ? (
                  <span style={{fontWeight: '600', textTransform: 'capitalize'}}>
                    {data.lastname}
                  </span>
                ) : (
                  <input
                    className='border border-N100'
                    type='text'
                    maxLength={45}
                    value={lastname}
                    placeholder='овог'
                    onChange={(e) => {
                      setLastName(e.target.value)
                      validateChange('lastname', e)
                    }}
                  />
                )}
                &nbsp;овогтой &nbsp;
                {!isNullOrEmpty(data) ? (
                  <span style={{fontWeight: '600', textTransform: 'capitalize'}}>
                    {data.firstname}{' '}
                  </span>
                ) : (
                  <input
                    className='border border-N100'
                    type='text'
                    value={firstname}
                    placeholder='нэр'
                    maxLength={45}
                    onChange={(e) => {
                      setFirstName(e.target.value)
                      validateChange('firstname', e)
                    }}
                  />
                )}
                /эдгээрийг тусад нь "Тал", хамтад нь "Талууд" гэх/ дор дурдсан нөхцөлийг хэлэлцэн
                тохиролцож, талуудын хооронд үүсэх харилцааг зохицуулах зорилгоор энэхүү гэрээг
                байгуулав.
              </h1>

              <span>
                <h2
                  style={{
                    fontSize: '20px',
                    fontWeight: '600',
                    textAlign: 'center',
                    margin: '20px 0 12px 0',
                  }}
                >
                  <span>Нэг. Нийтлэг үндэслэл</span>
                </h2>
                <h1 style={{fontSize: '20px', fontWeight: '400'}}>
                  Энэхүү гэрээгээр Үйлчилгээ авагчийн хүсэлтийн дагуу Үйлчилгээ авагч нь Зохиогчийн
                  эрхийн тухай хууль, Патентын тухай хуулиудаар хамгаалагдсан “НОВА ХЕКС” ХХК-ийн
                  дизайн платформ болох GRADO.MN -ийг өөрийн бизнес үйл ажиллагаандаа ажлаа
                  хөнгөвчлөх зорилгоор ашиглахад оршино.
                </h1>
                <h1 style={{fontSize: '20px', fontWeight: '400'}}>1.2&nbsp;&nbsp;Нэр томъёо:</h1>
                <h1
                  style={{
                    fontSize: '20px',
                    fontWeight: '400',
                    paddingLeft: '60px',
                  }}
                >
                  1.2.1&nbsp;&nbsp;Үйлчилгээ үзүүлэгч :&nbsp;“НОВА ХЕКС” ХХК
                </h1>
                <h1
                  style={{
                    fontSize: '20px',
                    fontWeight: '400',
                    paddingLeft: '60px',
                  }}
                >
                  1.2.2&nbsp;&nbsp;Үйлчилгээ авагч : “grado дизайн платформ”-ыг ашиглаж буй{' '}
                  <span style={{fontWeight: '600'}}>
                    {!isNullOrEmpty(data) ? data.org_name : invoiceData.org_name}
                  </span>
                </h1>
                <h1 style={{fontSize: '20px', fontWeight: '400'}}>
                  1.3&nbsp;&nbsp;Талуудын төлөөлөл, удирдах зөвлөл, гүйцэтгэх удирдлага, менежерүүд
                  өөрчлөгдсөн зэргээс үл хамааран гэрээний хугацааны турш хүчин төгөлдөр байна.
                </h1>
                <h1 style={{fontSize: '20px', fontWeight: '400'}}>
                  1.4&nbsp;&nbsp;Гэрээний хугацаа:{' '}
                  <span style={{fontWeight: '600'}}>
                    {formatDateToMongolian(invoiceData?.created_at)}
                  </span>
                  -ний өдрөөс эхлэн{' '}
                  <span style={{fontWeight: '600'}}>{formatDateToMongolian(contractend)}</span>
                  -ний өдрийг дуустал хүчинтэй.
                </h1>
                <h1 style={{fontSize: '20px', fontWeight: '400'}}>
                  1.5&nbsp;&nbsp;Идэвхижүүлсэн багц:{' '}
                  <span>
                    {invoiceData.skey === 'grado'
                      ? 'Grado.mn дизайн платформ'
                      : invoiceData.skey === 'qr'
                      ? 'qr.grado.mn'
                      : invoiceData.skey === 'flipbook'
                      ? 'flipbook.grado.mn'
                      : 'tool.grado.mn'}
                  </span>
                  -н
                  <span className='font-bold ml-1'>
                    {invoiceData.skey === 'grado' && invoiceData.subscription === 'sub1'
                      ? 'Стандарт багц'
                      : invoiceData.skey === 'grado' && invoiceData.subscription === 'sub2'
                      ? 'Байгууллага'
                      : invoiceData.skey === 'grado' && invoiceData.subscription === 'sub3'
                      ? 'Боловсрол'
                      : 'Супер'}{' '}
                    багц
                  </span>
                </h1>
                <h1
                  style={{
                    fontSize: '20px',
                    fontWeight: '400',
                    paddingLeft: '60px',
                  }}
                >
                  1.5.1&nbsp;&nbsp;Гэрээгээр үйлчилгээ идэвхжүүлэх хугацаа:{' '}
                  <span style={{fontWeight: '600'}}>{invoiceData?.month} сар</span>
                </h1>
                <h1
                  style={{
                    fontSize: '20px',
                    fontWeight: '400',
                    paddingLeft: '60px',
                  }}
                >
                  1.5.2&nbsp;&nbsp;Гэрээний дүн:{' '}
                  <span style={{fontWeight: '600'}}>
                    {moneyFormat(invoiceData?.total_price, 'MNT')}
                  </span>{' '}
                  /
                  <NumberToMongolian number={invoiceData?.total_price} />
                  -н төгрөг/
                </h1>
                <h1
                  style={{
                    fontSize: '20px',
                    fontWeight: '400',
                    paddingLeft: '60px',
                  }}
                >
                  1.5.3&nbsp;&nbsp;Багцад агуулагдсан мэдээллийг&nbsp;
                  <a
                    style={{color: 'black'}}
                    target='_blank'
                    href={
                      invoiceData?.skey === 'grado'
                        ? 'https://grado.mn/pricing'
                        : invoiceData?.skey === 'qr'
                        ? 'https://qr.grado.mn/pricing'
                        : invoiceData?.skey === 'flipbook'
                        ? 'https://flipbook.grado.mn/pricing'
                        : invoiceData?.skey === 'tool'
                        ? 'https://tool.grado.mn/pricing'
                        : ''
                    }
                  >
                    {invoiceData?.skey === 'grado'
                      ? 'https://grado.mn/pricing'
                      : invoiceData?.skey === 'qr'
                      ? 'https://qr.grado.mn/pricing'
                      : invoiceData?.skey === 'flipbook'
                      ? 'https://flipbook.grado.mn/pricing'
                      : invoiceData?.skey === 'tool'
                      ? 'https://tool.grado.mn/pricing'
                      : ''}
                  </a>
                  -аас аваарай
                </h1>
                <h2
                  style={{
                    fontSize: '20px',
                    fontWeight: '600',
                    textAlign: 'center',
                    margin: '20px 0 12px 0',
                  }}
                >
                  <span>Хоёр. Үйлчилгээ үзүүлэгчийн эрх, үүрэг</span>
                </h2>
                <h1 style={{fontSize: '20px', fontWeight: '400'}}>
                  2.1&nbsp;&nbsp;Үйлчилгээ үзүүлэгчийн эрх:
                </h1>
                <h1
                  style={{
                    fontSize: '20px',
                    fontWeight: '400',
                    paddingLeft: '60px',
                  }}
                >
                  2.1.1&nbsp;&nbsp;Үйлчилгээ авагчийн үүсгэсэн мэдээлэлд өөрсдийн үйлчилгээ үзүүлж
                  буй бүтээгдэхүүний шинэчлэлээ /update/ оруулах.
                </h1>
                <h1
                  style={{
                    fontSize: '20px',
                    fontWeight: '400',
                    paddingLeft: '60px',
                  }}
                >
                  2.1.2&nbsp;&nbsp;Хэрэглэгчдийн ачааллаас шалтгаалсан саатлыг засах зорилгоор
                  системийг түр зогсоож засварлах.
                </h1>
                <h1
                  style={{
                    fontSize: '20px',
                    fontWeight: '400',
                    paddingLeft: '60px',
                  }}
                >
                  2.1.3&nbsp;&nbsp;Гэрээний үүргээ хугацаанд нь зохих ёсоор биелүүлэхийг Үйлчилгээ
                  авагч талаас шаардах
                </h1>

                <h1
                  style={{
                    fontSize: '20px',
                    fontWeight: '400',
                    paddingLeft: '60px',
                  }}
                >
                  2.1.4&nbsp;&nbsp;Үйлчилгээ авагчийн хууль бус үйлдэл мөн түүнчлэн Үйлчилгээ
                  үзүүлэгчийн нэр төр, алдар хүндэд халдсан аливаа үйлдэл эс үйлдэхүйг таслан
                  зогсоох, түүний эсрэг шаардлага хүргүүлэх
                </h1>

                <h1
                  style={{
                    fontSize: '20px',
                    fontWeight: '400',
                    paddingLeft: '60px',
                  }}
                >
                  2.1.5&nbsp;&nbsp;Төлбөрийг хугацаанд нь төлөөгүйгээс үйлчилгээг түр хаах арга
                  хэмжээ авсан хугацаанд Үйлчилгээ авагчид учирсан аливаа хохирол, үр дагавар,
                  хариуцлагыг Үйлчилгээ үзүүлэгч хариуцахгүй байх
                </h1>

                <h1
                  style={{
                    fontSize: '20px',
                    fontWeight: '400',
                    paddingLeft: '60px',
                  }}
                >
                  2.1.6&nbsp;&nbsp;Үйлчилгээ үзүүлэгч нь зах зээлийн нөхцөл байдал, төрийн
                  байгууллагын шийдвэрийн улмаас үйлчилгээний нөхцөл, үнэ тарифыг өөрчлөх эрхтэй ба
                  энэ тохиолдолд Үйлчилгээ авагч талд өөрчлөлт орохоос ажлын 10-аас доошгүй хоногийн
                  өмнө веб хуудсанд мэдэгдэл оруулах.
                </h1>

                <h1 style={{fontSize: '20px', fontWeight: '400'}}>
                  2.2&nbsp;&nbsp;Үйлчилгээ үзүүлэгчийн үүрэг:
                </h1>
                <h1
                  style={{
                    fontSize: '20px',
                    fontWeight: '400',
                    paddingLeft: '60px',
                  }}
                >
                  2.2.1&nbsp;&nbsp;Системийг ашиглах явцад гарсан аливаа доголдлыг нөхцөл байдлаас
                  хамаарч 5 минутаас 24 цагийн хугацаанд засварлаж арилгана.
                </h1>
                <h1
                  style={{
                    fontSize: '20px',
                    fontWeight: '400',
                    paddingLeft: '60px',
                  }}
                >
                  2.2.2&nbsp;&nbsp;Үйлчилгээ үзүүлэгч тал нь 2.1.7д заасан тохиолдолд Үйлчилгээ
                  авагч талд өөрчлөлт орохоос ажлын 10-аас доошгүй хоногийн өмнө веб хуудаснд
                  мэдэгдэх үүрэгтэй.
                </h1>
                <h1
                  style={{
                    fontSize: '20px',
                    fontWeight: '400',
                    paddingLeft: '60px',
                  }}
                >
                  2.2.3&nbsp;&nbsp;Үйлчилгээ авагч талын дата мэдээ, мэдээллийн аюулгүй байдлыг
                  хангаж, гадны аливаа халдлагаас хамгаалж мэдээллийн нууцыг хадгална.
                </h1>

                <h1
                  style={{
                    fontSize: '20px',
                    fontWeight: '400',
                    paddingLeft: '60px',
                  }}
                >
                  2.2.4&nbsp;&nbsp;Системийг 24 цагийн тасралтгүй ажиллагаатай байлгах.
                </h1>
              </span>
            </div>
          </div>

          <div
            style={{
              height: loadingDownload ? '1800px' : '',
              padding: loadingDownload ? '20px 0' : '',
            }}
          >
            <h1
              style={{
                fontSize: '20px',
                fontWeight: '400',
                paddingLeft: '60px',
              }}
            >
              2.2.5&nbsp;&nbsp;Үйлчилгээ авагч талын санал, хүсэлт, гомдлыг түргэн шуурхай
              шийдвэрлэх.
            </h1>

            <h1
              style={{
                fontSize: '20px',
                fontWeight: '400',
                paddingLeft: '60px',
              }}
            >
              2.2.6&nbsp;&nbsp;Системд өөрчлөлт, шинэчлэлт гарах бүрд мэдэгдэл илгээж, шаардлагатай
              тохиолдолд сургалт зааварчилгаагаар хангах.
            </h1>

            <h1
              style={{
                fontSize: '20px',
                fontWeight: '400',
                paddingLeft: '60px',
              }}
            >
              2.2.7&nbsp;&nbsp;Үйлчилгээ авагч талын үйлчилгээ авах хугацаанд өөрийн буруу үйлдэлээс
              үүдэн төлбөр төлөх явцад болон платформ ашиглаж байх хугацаан алдаа гаргасан
              ториолдолд Үйлчилгээ үзүүлэгч тал хариуцлага хүлээхгүй.
            </h1>
            <h1
              style={{
                fontSize: '20px',
                fontWeight: '400',
                paddingLeft: '60px',
              }}
            >
              2.2.8&nbsp;&nbsp;Үйлчилгээ үзүүлэгч тал нь Үйлчилгээ авагч тал болон хэрэглэгчдийн
              мэдээллийг зөвхөн гэрээний үүргээ гүйцэтгэх зорилгоор хадгалах, ашиглах бөгөөд
              Үйлчилгээ авагч талын бичгэн хүсэлт эсхүл төрийн байгууллагын албан шаардлагаас бусад
              тохиолдолд гадагш задруулахгүй, гэрээний зорилгоос бусад зорилгоор ашиглахгүй байх.
            </h1>
            <h1
              style={{
                fontSize: '20px',
                fontWeight: '400',
                paddingLeft: '60px',
              }}
            >
              2.2.9&nbsp;&nbsp;Үйлчилгээ үзүүлэгч талын зүгээс үйлчлүүлэгч танын боловсруулан татаж
              авсан контентод хяналт тавьж дата мэдээлэл алдагдах, дамнан борлуулах эрсдэлээс
              сэргийлэх эрхтэй.
            </h1>

            <h2
              style={{
                fontSize: '20px',
                fontWeight: '600',
                textAlign: 'center',
                margin: '20px 0 12px 0',
              }}
            >
              <span>Гурав. Үйлчилгээ авагчийн эрх, үүрэг</span>
            </h2>
            <h1 style={{fontSize: '20px', fontWeight: '400'}}>
              3.1&nbsp;&nbsp;Үйлчилгээ авагчийн эрх:
            </h1>
            <h1
              style={{
                fontSize: '20px',
                fontWeight: '400',
                paddingLeft: '60px',
              }}
            >
              3.1.1&nbsp;&nbsp;Системийн бүрэн ажиллагааг Үйлчилгээ үзүүлэгчээс шаардах эрхтэй.
            </h1>
            <h1
              style={{
                fontSize: '20px',
                fontWeight: '400',
                paddingLeft: '60px',
              }}
            >
              3.1.2&nbsp;&nbsp;Нэмэлт ажлын үнэ хөлс, цаг хугацаа, хийх боломжтой эсэхийг тухайн үед
              харилцан тохиролцоно.
            </h1>
            <h1
              style={{
                fontSize: '20px',
                fontWeight: '400',
                paddingLeft: '60px',
              }}
            >
              3.1.3&nbsp;&nbsp;Хэрэглэгч нь үйлчилгээний чанарын талаар санал, хүсэлт, гомдлоо
              Үйлчилгээ үзүүлэгчийн 7777-9969 дугаарт болон info@grado.mn хаягруу илгээх эрхтэй.
            </h1>
            <h1
              style={{
                fontSize: '20px',
                fontWeight: '400',
                paddingLeft: '60px',
              }}
            >
              3.1.4&nbsp;&nbsp;Үзүүлж буй үйлчилгээнд санал, гомдлоо чөлөөтэй илэрхийлэх.
            </h1>
            <h1
              style={{
                fontSize: '20px',
                fontWeight: '400',
                paddingLeft: '60px',
              }}
            >
              3.1.5&nbsp;&nbsp;Өөрсдийн санал санаачлага хэрэгцээ шаардлагыг албан болон албан
              бусаар илэрхийлэх.
            </h1>

            <h1 style={{fontSize: '20px', fontWeight: '400'}}>
              {' '}
              3.2&nbsp;&nbsp;Үйлчилгээ авагчийн үүрэг:
            </h1>
            <h1
              style={{
                fontSize: '20px',
                fontWeight: '400',
                paddingLeft: '60px',
              }}
            >
              3.2.1&nbsp;&nbsp;Гэрээний үүргээ хугацаанд нь, зохих ёсоор гүйцэтгэж төлбөрийг заасан
              хугацааны дагуу бүрэн төлөх
            </h1>

            <h1
              style={{
                fontSize: '20px',
                fontWeight: '400',
                paddingLeft: '60px',
              }}
            >
              3.2.2&nbsp;&nbsp;Өөрийн байгууллага, үйл ажиллагааны талаар үнэн зөв мэдээлэл оруулах
            </h1>
            <h1
              style={{
                fontSize: '20px',
                fontWeight: '400',
                paddingLeft: '60px',
              }}
            >
              3.2.3&nbsp;&nbsp;Үйлчилгээ үзүүлэгч талын хууль ёсны эрх, ашиг сонирхолд халдахгүй
              байх
            </h1>
            <h1
              style={{
                fontSize: '20px',
                fontWeight: '400',
                paddingLeft: '60px',
              }}
            >
              3.2.4&nbsp;&nbsp;Төлбөр төлөгдөөгүйн улмаас үйлчилгээг түр хаасан хугацаан дахь аливаа
              хохирол, үр дагавар, хариуцлагыг Үйлчилгээ авагч тал бүрэн хариуцах
            </h1>
            <h1
              style={{
                fontSize: '20px',
                fontWeight: '400',
                paddingLeft: '60px',
              }}
            >
              3.2.5&nbsp;&nbsp;Гэрээнд заасан хугацаанд Дизайн платформын контент сан болон датаг
              гадагшаа алдуулахгүй байх
            </h1>
            <h1
              style={{
                fontSize: '20px',
                fontWeight: '400',
                paddingLeft: '60px',
              }}
            >
              3.2.6&nbsp;&nbsp;Градо дизайн платформын контентыг зөвхөн өөрийн хэрэгцээнд ашиглах ба
              дамнан борлуулахгүй байх.
            </h1>
            <h2
              style={{
                fontSize: '20px',
                fontWeight: '600',
                textAlign: 'center',
                margin: '20px 0 12px 0',
              }}
            >
              <span>Дөрөв. Талуудын хамтран хүлээх үүрэг, эдлэх эрх</span>
            </h2>
            <h1 style={{fontSize: '20px', fontWeight: '400'}}>
              4.1&nbsp;&nbsp;Талуудын хүлээх үүрэг:
            </h1>
            <h1
              style={{
                fontSize: '20px',
                fontWeight: '400',
                paddingLeft: '60px',
              }}
            >
              4.1.1&nbsp;&nbsp;Гэрээгээр хүлээсэн үүргээ шударгаар биелүүлэх
            </h1>
            <h1
              style={{
                fontSize: '20px',
                fontWeight: '400',
                paddingLeft: '60px',
              }}
            >
              4.1.2&nbsp;&nbsp;Талууд энэхүү Гэрээгээр хүлээсэн үүргээ биелүүлэхээс бусад зорилгоор
              нөгөө Талын нууцад хамаарах мэдээллийг ашиглахгүй, дамжуулахгүй, бусад зорилгоор
              ашиглахгүй байх.
            </h1>

            <h1
              style={{
                fontSize: '20px',
                fontWeight: '400',
                paddingLeft: '60px',
              }}
            >
              4.1.3&nbsp;&nbsp;Гэрээг байгуулах болон хэрэгжүүлэх явцад албан болон албан бусаар олж
              авсан хуулийн этгээдийн аливаа мэдээллийг бусдад дамжуулахгүй байх.
            </h1>

            <h1
              style={{
                fontSize: '20px',
                fontWeight: '400',
                paddingLeft: '60px',
              }}
            >
              4.1.4&nbsp;&nbsp;Талууд ашиг сонирхлоор нөгөө талын дата мэдээллээ бусдад дамжуулахгүй
              байх, нөгөө талын бизнес, үйл ажиллагааны аюулгүй байдлыг зөчихгүй байх.
            </h1>
            <h1 style={{fontSize: '20px', fontWeight: '400'}}>
              4.2&nbsp;&nbsp;Талуудын хүлээх эрх:
            </h1>
            <h1
              style={{
                fontSize: '20px',
                fontWeight: '400',
                paddingLeft: '60px',
              }}
            >
              4.2.1&nbsp;&nbsp;Аль нэг талын хүсэлтээр гэрээг цуцлах.
            </h1>
            <h2
              style={{
                fontSize: '20px',
                fontWeight: '600',
                textAlign: 'center',
                margin: '20px 0 12px 0',
              }}
            >
              <span>Тав. Төлбөр тооцоо</span>
            </h2>
            <h1 style={{fontSize: '20px', fontWeight: '400'}}>
              5.1&nbsp;&nbsp;Үйлчилгээ авагчийн Grado.mn дизайн платформыг үйлчилгээ үзүүлэх гэрээг
              үндэслэн ашиглана.
            </h1>
            <h1 style={{fontSize: '20px', fontWeight: '400'}}>
              5.2&nbsp;&nbsp;Гэрээ хийгдснээс хойш ажлын{' '}
              <span style={{fontWeight: '600'}}>5 хоногт</span> төлбөр тооцоо нь хийгдээгүй
              тохиолдолд энэхүү гэрээг хүчингүйд тооцно.
            </h1>
            <h1 style={{fontSize: '20px', fontWeight: '400'}}>
              5.3&nbsp;&nbsp;Үйлчилгээ авагч тал нь энэхүү Гэрээг байгуулснаас ажлын 3 өдрийн дотор
              Үйлчилгээ үзүүлэгчийн нэхэмжлэхийн дагуу{' '}
              <span style={{fontWeight: '600'}}>“НОВА ХЕКС”</span> -ийн{' '}
              <span style={{fontWeight: '600'}}>М банкин</span> дахь{' '}
              <span style={{fontWeight: '600'}}>9006942326</span> тоот дансанд шилжүүлнэ.{' '}
              <span style={{fontWeight: '600'}}>/ Гүйлгээний утга: гэрээний дугаар байна./</span>
            </h1>
            <h1 style={{fontSize: '20px', fontWeight: '400'}}>
              5.4&nbsp;&nbsp;Гэрээнд хийгдсэн төлбөр тооцоо хийгдэж мэдэгдсэнээр тухайн Үйлчилгээ
              авагч талын Градо дизайн платформ ашиглах эрх нь идэвхэжнэ.
            </h1>
            <h1 style={{fontSize: '20px', fontWeight: '400'}}>
              5.5&nbsp;&nbsp;Үйлчилгээ авагч тал нь Градо дизайн платформыг гэрээний хугацаанд
              ашиглахгүй байх эсвэл ажилтны тоо цөөрөх, гэрээний хугацаанаас өмнө гэрээг цуцлах нь
              төлбөр бууруулах буюу буцаан олгох үндэслэл болохгүй.
            </h1>

            <h2
              style={{
                fontSize: '20px',
                fontWeight: '600',
                textAlign: 'center',
                margin: '20px 0 12px 0',
              }}
            >
              Зургаа. Гэрээний хугацаа
            </h2>
            <h1 style={{fontSize: '20px', fontWeight: '400'}}>
              6.1&nbsp;&nbsp;Гэрээний хугацаа {formatDateToMongolian(invoiceData.created_at)} өдрөөс
              эхлэн {formatDateToMongolian(contractend)} хүртэл {invoiceData.month} сарын хугацаанд
              хүчинтэй байна.
            </h1>
            
          </div>

          <div
            style={{
              height: 'fit-content',
              paddingTop: loadingDownload ? '180px' : '',
            }}
          >
            <h1 style={{fontSize: '20px', fontWeight: '400'}}>
              6.2&nbsp;&nbsp;Энэхүү гэрээний хугацаа дуусгавар болоход аль нэг тал нь гэрээг
              үргэлжлүүлэх тухай харилцан тохиролцоогүй, системээс үйлчилгээний хугацаагаа 7
              хоногийн дотор сунгаагүй тохиолдолд гэрээг цуцлагдсанд тооцно.
            </h1>
            <h1 style={{fontSize: '20px', fontWeight: '400'}}>
              6.3&nbsp;&nbsp;Дараах тохиолдолд талууд гэрээг хугацаанаас нь өмнө цуцалж болно. Үүнд:
            </h1>
            <h1
              style={{
                fontSize: '20px',
                fontWeight: '400',
                paddingLeft: '60px',
              }}
            >
              6.3.1&nbsp;&nbsp;Талууд харилцан тохиролцсоноор
            </h1>
            <h1
              style={{
                fontSize: '20px',
                fontWeight: '400',
                paddingLeft: '60px',
              }}
            >
              6.3.2&nbsp;&nbsp;Талуудын аль нэг нь гэрээгээр хүлээсэн үүргээ биелүүлээгүй тохиолдолд
              нөгөө талын санаачилгаар.
            </h1>

            <h2
              style={{
                fontSize: '20px',
                fontWeight: '600',
                textAlign: 'center',
                margin: '20px 0 12px 0',
              }}
            >
              <span>Долоо. Градо дизайн платформ </span>
            </h2>
            <h1 style={{fontSize: '20px', fontWeight: '400'}}>
              7.1&nbsp;&nbsp;Градо дизайн платформ нь доорх багцуудыг санал болгодог:
            </h1>
            <h1
              style={{
                fontSize: '20px',
                fontWeight: '400',
                paddingLeft: '60px',
              }}
            >
              7.1.1&nbsp;&nbsp;“Стандарт багц” буюу нэг эрхтэй багц. Үнийн дүн: 35,000 төгрөг /НӨАТ
              орсон/
            </h1>
            <h1
              style={{
                fontSize: '20px',
                fontWeight: '400',
                paddingLeft: '60px',
              }}
            >
              7.1.2&nbsp;&nbsp;"Байгууллага багц” багц. Үнийн дүн 59,000 төгрөг /НӨАТ орсон/
            </h1>
            <h1
              style={{
                fontSize: '20px',
                fontWeight: '400',
                paddingLeft: '60px',
              }}
            >
              7.1.3&nbsp;&nbsp;“Боловсрол багц” буюу олон эрх холбох багц. Үнийн дүн 99,000 төгрөг
              /НӨАТ орсон/
            </h1>
            <h1
              style={{
                fontSize: '20px',
                fontWeight: '400',
                paddingLeft: '60px',
              }}
            >
              7.1.4&nbsp;&nbsp;“Супер багц” нь 7.1.2 багцийг агуулахаас гадна Градо дизайн
              платформын qr.grado.mn систем, flipbook.grado.mn систем, tool.grado.mn системийн
              багцуудыг ашиглах эрхтэй багц. Үнийн дүн: 140,000 төгрөг /НӨАТ орсон/
              <br />
              <br />
              /Дээрх багцуудын үнийн мэдээлэл нь {formatDateToMongolian(invoiceData.created_at)}
              -ийн мэдээлэл болно./
            </h1>

            <h1 style={{fontSize: '20px', fontWeight: '400'}}>
              7.2&nbsp;&nbsp;Үйлчилгээ үзүүлэгч нь Үйлчилгээ авагч талын төхөөрөмжийн хүчин чадал
              болон программ хангамж, цахилгааны хэлбэлзэл, интернэт холболт, сүлжээний гэмтэлээс
              шалтгаалсан платформ ашиглах явцад гарсан Үйлчилгээ авагч талын саатлыг хариуцахгүй.
            </h1>
            <h1 style={{fontSize: '20px', fontWeight: '400'}}>
              7.3&nbsp;&nbsp;Хүчинтэй хугацаа дуусанаар Үйлчилгээ авагч талын платформд хандах эрх
              нь хязгаарлагдах ба үйчилгээ авах хугацаанд хадгалсан контент сан нь 3 сарын хугацаанд
              хадгалагдахын анхааруулж байна.
            </h1>

            <h1 style={{fontSize: '20px', fontWeight: '400'}}>
              7.4&nbsp;&nbsp;Үйлчилгээ үзүүлэгч тал нь багцын хүчинтэй хугацаанд тухайн багцад
              хамаарах үйлчилгээ, хугацаа, төлбөрийг өөрчлөхгүй.
            </h1>
            <h1 style={{fontSize: '20px', fontWeight: '400'}}>
              7.5&nbsp;&nbsp;Үйлчилгээ авагч тал нь Градо дизайн платформтой холбоотой санал,
              хүсэлт, гомдлыг ажлын өдөр, 09:00 цагаас 18:00 цагийн хооронд Үйлчилгээ үзүүлэгч талын
              7777-9969 утас болон info@grado.mn имэйл хаягаар хүргүүлж болно.
            </h1>

            <h2
              style={{
                fontSize: '20px',
                fontWeight: '600',
                textAlign: 'center',
                margin: '20px 0 12px 0',
              }}
            >
              <span>Найм. Маргаан шийдвэрлэх</span>
            </h2>
            <h1 style={{fontSize: '20px', fontWeight: '400'}}>
              8.1&nbsp;&nbsp;Энэхүү гэрээний явцад гарсан аливаа маргааныг Талууд нэн түрүүнд
              зөвшилцөх замаар шийдвэрлэнэ.
            </h1>
            <h1 style={{fontSize: '20px', fontWeight: '400'}}>
              8.2&nbsp;&nbsp;Хэрэв талууд 30 хоногийн дотор гарсан маргаан, зөрчилдөөнийг шийдвэрлэж
              чадаагүй тохиолдолд Монгол Улсын шүүхээр шийдвэрлүүлнэ.
            </h1>
            <h1 style={{fontSize: '20px', fontWeight: '400'}}>
              8.3&nbsp;&nbsp;Талууд энэхүү гэрээнд тусгагдаагүй асуудлыг зохих хууль тогтоомжийг
              үндэслэн шийдвэрлэнэ.
            </h1>
            <h2
              style={{
                fontSize: '20px',
                fontWeight: '600',
                textAlign: 'center',
                margin: '20px 0 12px 0',
              }}
            >
              <span>Ес. Нууцлал</span>
            </h2>
            <h1 style={{fontSize: '20px', fontWeight: '400'}}>
              9.1&nbsp;&nbsp;Үйлчилгээ үзүүлэгч тал нь гэрээний хэрэгжилтийн явцад, мөн түүнийг
              дуусгавар болсноос хойш хугацаагүйгээр энэ гэрээтэй холбоотой өмчлөлийн болон нууцлах
              шаардлагатай мэдээллийг, эсхүл талуудын бизнесийн болон үйл ажиллагааны талаарх
              мэдээллийг задруулах эрхгүй.
            </h1>
            <h1 style={{fontSize: '20px', fontWeight: '400'}}>
              9.2&nbsp;&nbsp;Үйлчилгээ үзүүлэгч талын үйлчилгээ нь оюуны өмчөөр баталгаажсан болно.
            </h1>
            <h2
              style={{
                fontSize: '20px',
                fontWeight: '600',
                textAlign: 'center',
                margin: '60px 0 30px 0',
              }}
            >
              <span>ГЭРЭЭ БАЙГУУЛСАН</span>
            </h2>
            <div style={{fontSize: '18px'}} className='flex justify-between mb-10'>
              <div className='flex flex-col gap-2' style={{width: '420px', position: 'relative'}}>
                <span className='' style={{fontWeight: '600'}}>
                  ҮЙЛЧИЛГЭЭ ҮЗҮҮЛЭГЧ ТАЛ:
                </span>
                <div className='flex flex-col gap-1'>
                  <span>Хаяг:</span>
                  <span className='flex flex-col gap-0'>
                    <span style={{lineHeight: '120%'}}>
                      Улаанбаатар хот, Сvхбаатар дvvрэг,Бага тойруу-49, Мэдээлэл технологийн
                      үндэсний парк, 1 давхар 108 тоот
                    </span>
                  </span>
                </div>

                <span>Үйлчилгээ үзүүлэгч талыг төлөөлж:</span>
                <span>Албан тушаал: ГҮЙЦЭТГЭХ ЗАХИРАЛ</span>
                <span>Нэр: Б. АЯТХААН</span>
                <span>Гарын үсэг</span>
                <img
                  style={{
                    position: 'absolute',
                    top: '190px',
                    left: '100px',
                  }}
                  width={180}
                  src={'/images/signature.png'}
                />
                <span className='mt-5'>Утас: 7777-9969</span>
                <img
                  style={{
                    position: 'absolute',
                    top: '200px',
                    left: '130px',
                  }}
                  width={300}
                  src={'/images/stamp.png'}
                />
              </div>
              <div className='flex flex-col gap-2' style={{width: '480px'}}>
                <span className='' style={{fontWeight: '600'}}>
                  ҮЙЛЧИЛГЭЭ АВАГЧ ТАЛ:
                </span>
                <div className='flex gap-2 w-[500px]'>
                  <div className='flex flex-col gap-1'>
                    <span>Хаяг:</span>
                    {!isNullOrEmpty(data) ? (
                      <span style={{lineHeight: '120%'}}>{data.address}</span>
                    ) : (
                      <div className='flex flex-col gap-1'>
                        <textarea
                          value={address}
                          onChange={(e) => {
                            setAddress(e.target.value)
                            validateChange('address', e)
                          }}
                          placeholder='Байгууллагын хаягаа энд бичнэ үү'
                          className={`w-[350px] h-[80px] resize-none px-2 py-1 border border-N100 ${
                            validate['address'] ? '!border-red' : ''
                          }`}
                        />
                        {validate['address'] ? (
                          <div className='validate-noti'>Хаягийн мэдээлэлээ оруулна уу.</div>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
                <span>Үйлчилгээ авагч талыг төлөөлж :</span>
                <div className='flex gap-2 items-center'>
                  <span>Албан тушаал:</span>
                  {!isNullOrEmpty(data) ? (
                    <span style={{lineHeight: '120%'}}>{data.albantushaal}</span>
                  ) : (
                    <div className='flex flex-col gap-1'>
                      <input
                        type='text'
                        maxLength={90}
                        onChange={(e) => {
                          setAlbantushaal(e.target.value)
                          validateChange('albantushaal', e)
                        }}
                        value={albantushaal}
                        placeholder='Албан тушаал бичих'
                        className={`border border-N100 ${
                          validate['albantushaal'] ? '!border-red' : ''
                        }`}
                      />
                      {validate['albantushaal'] ? (
                        <div className='validate-noti'>Албан тушаал оруулна уу.</div>
                      ) : null}
                    </div>
                  )}
                </div>
                <div className='flex gap-2 items-center'>
                  <span>Овог:</span>
                  {!isNullOrEmpty(data) ? (
                    <span style={{textTransform: 'capitalize'}}>{data.lastname}</span>
                  ) : (
                    <div className='flex flex-col gap-1'>
                      <input
                        type='text'
                        maxLength={45}
                        onChange={(e) => {
                          setLastName(e.target.value)
                          validateChange('lastname', e)
                        }}
                        placeholder='Гэрээ хийж буй хүний овог'
                        value={lastname}
                        className={`border border-N100 ${
                          validate['lastname'] ? '!border-red' : ''
                        }`}
                      />
                      {validate['lastname'] ? (
                        <div className='validate-noti'>Овог оруулна уу.</div>
                      ) : null}
                    </div>
                  )}
                </div>
                <div className='flex gap-2 items-center'>
                  <span>Нэр:</span>
                  {!isNullOrEmpty(data) ? (
                    <span style={{textTransform: 'capitalize'}}>{data.firstname}</span>
                  ) : (
                    <div className='flex flex-col gap-1'>
                      <input
                        type='text'
                        maxLength={45}
                        onChange={(e) => {
                          setFirstName(e.target.value)
                          validateChange('firstname', e)
                        }}
                        value={firstname}
                        placeholder='Гэрээ хийж буй хүний нэр'
                        className={` border border-N100 ${
                          validate['firstname'] ? '!border-red' : ''
                        }`}
                      />
                      {validate['firstname'] ? (
                        <div className='validate-noti'>Нэр оруулна уу.</div>
                      ) : null}
                    </div>
                  )}
                </div>
                <div className='flex  gap-2 items-center'>
                  <span>Утас:</span>
                  {!isNullOrEmpty(data) ? (
                    <span>{data.phone}</span>
                  ) : (
                    <div className='flex flex-col gap-1'>
                      <input
                        type='text'
                        maxLength={8}
                        onChange={(e) => {
                          setMobile(e.target.value)
                          validateChange('mobile', e)
                        }}
                        value={mobile}
                        placeholder='Утасны дугаар бичих'
                        className={`border border-N100 ${validate['mobile'] ? '!border-red' : ''}`}
                      />
                      {validate['mobile'] ? (
                        <div className='validate-noti'>Утасны дугаар оруулна уу.</div>
                      ) : null}
                    </div>
                  )}
                </div>

                <div className='flex gap-3'>
                  <span style={{fontSize: '20px'}} className='form-label'>
                    Гарын үсэг:
                  </span>
                  <div
                    style={{
                      backgroundImage: `url(${base64signature})`,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      height: '90px',
                      width: '240px',
                    }}
                  ></div>
                </div>
                {!isNullOrEmpty(data) ? null : (
                  <div className='flex justify-end mt-5 w-full'>
                    <button onClick={saveContract} className='btn-primary px-6 '>
                      Гэрээ хийх
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContractPage
