/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useTranslation} from 'react-i18next'
import config from '../../../../services/config.json'
import {IsPrivilege} from '../../../../services/Utils'
export function AsideMenuMain() {
  const intl = useIntl()
  const {t} = useTranslation()
  const debugMode = config.debug
  return (
    <>
      {debugMode == 'test1' ? (
        <AsideMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' />
      ) : null}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {t('common:Dashboard')}
          </span>
        </div>
      </div>

      <AsideMenuItem
        to='/dashboard'
        icon='color-swatch'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {IsPrivilege('subscription-list-POST') ? (
        <AsideMenuItemWithSub
          to='/subscription'
          title={t('main:menu.subscription')}
          fontIcon='bi-archive'
          icon='element-plus'
        >
          <AsideMenuItem to='/subscription' title={t('main:menu.subscription')} hasBullet={true} />
        </AsideMenuItemWithSub>
      ) : null}
      {IsPrivilege('designer-list-POST') ? (
        <AsideMenuItemWithSub
          to='/users'
          title={t('main:menu.users')}
          fontIcon='bi-archive'
          icon='user-tick'
        >
          <AsideMenuItem to='/users' title={t('main:menu.users')} hasBullet={true} />
        </AsideMenuItemWithSub>
      ) : null}
      {IsPrivilege('designer-list-POST') ? (
        <AsideMenuItemWithSub
          to='/customer'
          title="Customer"
          fontIcon='bi-archive'
          icon='user-tick'
        >
          <AsideMenuItem to='/customer' title="Customer" hasBullet={true} />
          <AsideMenuItem to='/invoices' title="Invoices" hasBullet={true} />
          <AsideMenuItem to='/contracts' title="Contracts" hasBullet={true} />
        </AsideMenuItemWithSub>
      ) : null}
      {IsPrivilege('org-list-POST') ? (
        <AsideMenuItemWithSub
          to='/orgs'
          title={t('main:menu.org')}
          fontIcon='bi-archive'
          icon='briefcase'
        >
          <AsideMenuItem to='/school' title={t('main:menu.school_list')} hasBullet={true} />
          <AsideMenuItem to='/orgs' title={t('main:menu.org_list')} hasBullet={true} />
          <AsideMenuItem to='/org/logos' title={t('main:menu.org_logo')} hasBullet={true} />
          <AsideMenuItem to='/org/colors' title={t('main:menu.org_color')} hasBullet={true} />
          <AsideMenuItem to='/org/fonts' title={t('main:menu.org_fonts')} hasBullet={true} />
          <AsideMenuItem to='/org/elements' title={t('main:menu.org_elements')} hasBullet={true} />
          <AsideMenuItem to='/org/powerpoints' title={t('main:menu.org_powerpoint')} hasBullet={true} />
          <AsideMenuItem to='/org/PDF' title={t('main:menu.org_PDF')} hasBullet={true} />
        </AsideMenuItemWithSub>
      ) : null}
      {/* {IsPrivilege('template-list-POST') ? ( */}
        <AsideMenuItemWithSub
          to='/template'
          title={t('main:menu.template')}
          fontIcon='bi-archive'
          icon='folder-added'
        >
          <AsideMenuItem to='/templates' title={t('main:menu.templates')} hasBullet={true} />
          <AsideMenuItem to='/userstemplate' title={t('main:menu.user_template')} hasBullet={true} />
          <AsideMenuItem to='/userstemplatecreated' title={t('main:menu.user_template') + " by users"}  hasBullet={true} />
          <AsideMenuItem to='/fonts' title={t('main:menu.fonts')} hasBullet={true} />
          <AsideMenuItem to='/element' title={t('main:menu.element')} hasBullet={true} />

        </AsideMenuItemWithSub>
      {/* ) : null} */}
      <AsideMenuItemWithSub
          to='/qr'
          title={t('main:menu.qr')}
          fontIcon='bi-archive'
          icon='scan-barcode'
        >
          <AsideMenuItem to='/qr' title={t('main:menu.qr')} hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub
          to='/flipbook'
          title="Flipbook"
          fontIcon='bi-archive'
          icon='book-open'
        >
          <AsideMenuItem to='/flipbook' title="Flipbook" hasBullet={true} />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub
          to='/travel'
          title="Travel"
          fontIcon='bi-archive'
          icon='book-open'
        >
          <AsideMenuItem to='/travel' title="Travel" hasBullet={true} />
          <AsideMenuItem to='/route' title="Route" hasBullet={true} />

        </AsideMenuItemWithSub>
      {IsPrivilege('dyorder-list-POST') ? (
        <AsideMenuItemWithSub
          to='/letterbanner'
          title={t('main:menu.letterbanner')}
          fontIcon='bi-archive'
          icon='flag'
        >
          <AsideMenuItem
            to='/letterbanners'
            title={t('main:menu.letterbanner_list')}
            hasBullet={true}
          />
          <AsideMenuItem
            to='/letterbanner/last'
            title={t('main:menu.letterbanner_last')}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      ) : null}
      <AsideMenuItemWithSub
          to='/event'
          title="Event"
          fontIcon='bi-archive'
          icon='award'
        >
          <AsideMenuItem to='/event' title="Event" hasBullet={true} />
        </AsideMenuItemWithSub>
      {IsPrivilege('employee-getallemployee-POST') ? (
        <AsideMenuItemWithSub
          to='/employee'
          title={t('main:menu.employee')}
          fontIcon='bi-archive'
          icon='people'
        >
          <AsideMenuItem to='/employee' title={t('main:menu.employeelist')} hasBullet={true} />
          <AsideMenuItem
            to='/privilege/privgrouplist'
            title={t('main:menu.privgrouplist')}
            hasBullet={true}
          />
          <AsideMenuItem
            to='/privilege/privlist'
            title={t('main:menu.privlist')}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      ) : null}
      {IsPrivilege('menu-news') ? (
        <AsideMenuItemWithSub
          to='/news'
          title={t('main:menu.news')}
          fontIcon='bi-archive'
          icon='element-plus'
        >
          <AsideMenuItem to='/news' title={t('main:menu.news')} hasBullet={true} />
          <AsideMenuItem to='/pages' title={t('main:menu.pages')} hasBullet={true} />
          <AsideMenuItem to='/videos' title={t('main:menu.videos')} hasBullet={true} />
        </AsideMenuItemWithSub>
      ) : null}
      {/* {IsPrivilege('menu-feedback') ? ( */}
      <AsideMenuItemWithSub
        to='/feedback'
        title={t('main:menu.feedback')}
        fontIcon='bi-archive'
        icon='message-question'
      >
        <AsideMenuItem to='/feedback' title={t('main:menu.feedback')} hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/searching'
        title="Searching"
        fontIcon='bi-archive'
        icon='magnifier'
      >
        <AsideMenuItem to='/search-result' title="Searching by result" hasBullet={true} />
        <AsideMenuItem to='/search-times' title="Searching by times" hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* ) : null} */}
      {debugMode == 'test1' ? (
        <>
          <AsideMenuItemWithSub
            to='/crafted/pages'
            title='Pages'
            fontIcon='bi-archive'
            icon='element-plus'
          >
            <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
              <AsideMenuItem
                to='/crafted/pages/profile/overview'
                title='Overview'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/crafted/pages/profile/projects'
                title='Projects'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/crafted/pages/profile/campaigns'
                title='Campaigns'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/crafted/pages/profile/documents'
                title='Documents'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/crafted/pages/profile/connections'
                title='Connections'
                hasBullet={true}
              />
            </AsideMenuItemWithSub>

            <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
              <AsideMenuItem
                to='/crafted/pages/wizards/horizontal'
                title='Horizontal'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/crafted/pages/wizards/vertical'
                title='Vertical'
                hasBullet={true}
              />
            </AsideMenuItemWithSub>
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub
            to='/crafted/accounts'
            title='Accounts'
            icon='profile-circle'
            fontIcon='bi-person'
          >
            <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
            <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
            <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
            <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub
            to='/crafted/widgets'
            title='Widgets'
            icon='element-11'
            fontIcon='bi-layers'
          >
            <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
            <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
            <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
            <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
            <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
            <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
          </AsideMenuItemWithSub>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
            </div>
          </div>
          <AsideMenuItemWithSub
            to='/apps/chat'
            title='Chat'
            fontIcon='bi-chat-left'
            icon='message-text-2'
          >
            <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
            <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
            <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
          </AsideMenuItemWithSub>
          <AsideMenuItem
            to='/apps/user-management/users'
            icon='shield-tick'
            title='User management'
            fontIcon='bi-layers'
          />
        </>
      ) : null}
    </>
  )
}
