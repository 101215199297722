import React, { useEffect, useState } from "react";

const convertNumberToMongolian = (num) => {
  if (isNaN(num) || num < 0) return "Invalid input";
  if (num === 0) return "тэг";

  const numberWords = [
    { name0: "", name10: "", nameMiddle: "", name10End: "" },
    { name0: "нэг", name10: "арван", nameMiddle: "нэгэн", name10End: "арав", name3: "нэг" },
    { name0: "хоёр", name10: "хорин", nameMiddle: "хоёр", name10End: "хорь", name3: "хоёр" },
    { name0: "гурав", name10: "гучин", nameMiddle: "гурван", name10End: "гуч", name3: "гурван" },
    { name0: "дөрөв", name10: "дөчин", nameMiddle: "дөрвөн", name10End: "дөч", name3: "дөрвөн" },
    { name0: "тав", name10: "тавин", nameMiddle: "таван", name10End: "тавь", name3: "таван" },
    { name0: "зургаа", name10: "жаран", nameMiddle: "зургаан", name10End: "жар", name3: "зургаан" },
    { name0: "долоо", name10: "далан", nameMiddle: "долоон", name10End: "дал", name3: "долоон" },
    { name0: "найм", name10: "наян", nameMiddle: "найман", name10End: "ная", name3: "найман" },
    { name0: "ес", name10: "ерэн", nameMiddle: "есөн", name10End: "ер", name3: "есөн" },
  ];

  const scales = ["", "мянга", "сая", "тэрбум"];
  let words = [];

  const convertChunk = (chunk, scaleIndex) => {
    let chunkWords = [];
    if (chunk >= 100) {
      const hundreds = Math.floor(chunk / 100);
      chunkWords.push(numberWords[hundreds].name3);
      chunkWords.push("зуун");
      chunk %= 100;
    }
    if (chunk >= 10) {
      const tens = Math.floor(chunk / 10);
      chunkWords.push(numberWords[tens].name10);
      chunk %= 10;
    }
    if (chunk > 0) {
      const wordForm = scaleIndex > 0 ? numberWords[chunk].nameMiddle : numberWords[chunk].name0;
      chunkWords.push(wordForm);
    }
    if (chunkWords.length > 0 && scales[scaleIndex]) {
      chunkWords.push(scales[scaleIndex]);
    }
    return chunkWords.join(" ");
  };

  let chunks = [];
  while (num > 0) {
    chunks.push(num % 1000);
    num = Math.floor(num / 1000);
  }

  for (let i = 0; i < chunks.length; i++) {
    if (chunks[i] > 0) {
      words.unshift(convertChunk(chunks[i], i));
    }
  }

  return words.join(" ").trim();
};

const NumberToMongolian = ({ number }) => {
  const [result, setResult] = useState("");

  useEffect(() => {
    setResult(convertNumberToMongolian(parseInt(number, 10)));
  }, [number]);

  return <span>{result || "тэг"}</span>;
};

export default NumberToMongolian;
