import React, {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {dateAgo, dateTimeFormat, isNullOrEmpty, subscriptionName} from '../../../services/Utils'
import {
  BaseRequest,
  NewsChangeStatusReq,
  SubscriptionConnectOrgReq,
  SubscriptionListReq,
  magicnum,
} from '../../../services/Request'
import {
  CheckRdtoName,
  NewsChangeStatus,
  SubscriptionConnectOrg,
  SubscriptionDelete,
  SubscriptionList,
} from '../../../services/main'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import CustomModal from '../../modules/CustomModal/modal'
import {spawn} from 'child_process'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

type Props = {
  type?: string
  crd?: any
  uid?: any
}

const Subscription: FC<Props> = ({type,crd,uid}) => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [validate, setValidate] = useState<any>([])
  const [item, setItem] = useState<any>('')
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [count, setCount] = useState(1)
  const [rd, setRd] = useState('')
  const [sizePerPage, setSizePerPage] = useState(10)
  const [showModal, updateShowModal] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [orgAddModal, setOrgAddModal] = useState(false)
  const [loadingCheck, setLaodingCheck] = useState(false)
  const [name, setName] = useState('')

  useEffect(() => {
    setName('')
    if (rd.length > 6) {
      setLaodingCheck(true)
      CheckRdtoName(rd, BaseRequest, onCheckSuccess, onFailed, 'GET')
    }
  }, [rd])

  useEffect(() => {
    list()
  }, [PID])

  const onCheckSuccess = (res: any) => {
    setLaodingCheck(false)
    setName(res.data.name)
  }
  const save = () => {
    setLoading(true)
    var req = SubscriptionConnectOrgReq
    req.subscription.id = item.id
    req.subscription.org_rd = rd
    SubscriptionConnectOrg(req, addSuccess, onFailed, 'PUT')
  }
  const addSuccess = () => {
    setLoading(false)
    setOrgAddModal(false)
    list()
    return toast.success('Байгууллага амжилттай холбогдлоо')
  }

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = SubscriptionListReq
    req.pid = PID
    req.pSize = sizePerPage
    req.filter.org_rd = crd
    req.filter.uid = uid
    SubscriptionList(req, onSuccess, onFailed, 'POST')
  }

  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }

  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }

  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }

  const defaultRowFormat = (cell: any, row: any) => {
    return <>{cell}</>
  }

  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div style={{minWidth: '120px'}} className='text-center'>
        <Link to={`/newsmore/${cell}?title=${row.title}`}>
          <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
            <KTIcon iconName='switch' className='fs-3' />
          </div>
        </Link>
        <div
          title='Add user to Costumer'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            setOrgAddModal(true)
          }}
        >
          {isNullOrEmpty(row.org_rd) ? (
            <i className='fa-solid fa-city fs-5'></i>
          ) : (
            <i className='fa-solid fa-city fs-5' style={{color: 'green'}}></i>
          )}
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            updateShowModal(true)
          }}
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }

  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  deleteFunc(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }

  const deleteFunc = (row: any) => {
    SubscriptionDelete(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }

  const onDeleteSuccess = () => {
    list()
    return toast.success(t('common:successDelete'))
  }

  const rowDateFormat = (cell: any, row: any) => {
    return (
      <div
        className='text-center cursor-pointer'
        style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
      >
        {dateAgo(cell) < 1 ? (
          <span className='badge badge-light-warning'>Сунгалт дууссан : {dateAgo(cell)}</span>
        ) : (
          <span className='badge badge-light-success'>Хүчинтэй өдөр : {dateAgo(cell)}</span>
        )}
        <span style={{fontSize: 10}}>{dateTimeFormat(cell)}</span>
      </div>
    )
  }

  const subsRowFormat = (cell: any, row: any) => {
    return (
      <div
        className='text-center cursor-pointer'
        style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
      >
        <span>{row.skey}</span>
        <span style={{fontSize: 10, padding: '2px 6px', backgroundColor: ''}}>
          {subscriptionName(row.skey, cell)}
        </span>
        <span style={{fontSize: 10, minWidth: '43px'}}>{row.sungasan_sar} сар</span>
      </div>
    )
  }

  const barimtRowFormat = (cell: any, row: any) => {
    return (
      <div
        className='text-center cursor-pointer'
        style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
      >
        <span style={{fontSize: 10}}>{cell}</span>
        {isNullOrEmpty(row.org_rd) ? null : (
          <span style={{fontSize: 10}}>
            {t('common:SUBSCRIPTION.org_rd')} : {row.org_rd}
          </span>
        )}
      </div>
    )
  }

  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: t('common:SUBSCRIPTION.bagts'),
        dataField: 'bagts',
        dataSort: false,
        dataFormat: subsRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:SUBSCRIPTION.ebarimt_email'),
        dataField: 'ebarimt_email',
        dataSort: false,
        dataFormat: barimtRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:SUBSCRIPTION.duusah_hugatsaa'),
        dataField: 'duusah_hugatsaa',
        dataSort: false,
        dataFormat: rowDateFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:SUBSCRIPTION.created_at'),
        dataField: 'created_at',
        dataSort: false,
        dataFormat: dateTimeFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:SUBSCRIPTION.action'),
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <>
      {orgAddModal ? (
        <CustomModal
          visible={orgAddModal}
          closeModal={() => updateShowModal(false)}
          className='VideoModal'
        >
          <div className='scroll-x mx-5 mx-xl-10 pt-10 pb-10 '>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Харилцагч нэмэх</h1>
            </div>

            <div className='separator mt-8 d-flex flex-center mb-8'>
              <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>text</span>
            </div>
            <div className='mb-4'>
              <input
                className='form-control form-control-solid w-300px'
                placeholder='Харилцагчын регистр'
                disabled={loadingCheck}
                onChange={(e) => {
                  setRd(e.target.value)
                }}
                value={loadingCheck ? `${rd} Уншиж байна...` : rd}
                type='text'
                maxLength={7}
              />
            </div>

            <div className='mb-4'>
              <input
                className='form-control form-control-solid w-300px'
                value={name}
                type='text'
                disabled={true}
              />
            </div>

            <div className='pt-4' style={{textAlign: 'right'}}>
              <button
                className='btn btn-primary align-self-center'
                onClick={save}
                disabled={loading}
              >
                {loading ? t('common:loading') : <span>save</span>}
              </button>
            </div>
          </div>
        </CustomModal>
      ) : null}
      {type != 'component' ? (
        <div className={`card mb-5`}>
          <div className='card-header border-1' style={{height: '60px'}}>
            <div
              className='filter flex items-center justify-center gap-0'
              onClick={() => setShowFilter(!showFilter)}
            >
              <KTIcon iconName='filter' className='fs-3' />
              <span className='text'>{t('common:filter')}</span>
            </div>
            <div className='card-toolbar'>
              <a href='#' className='btn btn-sm btn-light-primary'>
                {t('common:search')}
              </a>
            </div>
          </div>
          {showFilter ? <div className='card-body py-3'>d</div> : null}
        </div>
      ) : null}
      {type != 'component' ? (
        <PageTitle breadcrumbs={dashboardBreadCrumbs}>
          {t('common:SUBSCRIPTION.pagetitle')}
        </PageTitle>
      ) : null}

      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{t('common:SUBSCRIPTION.list')}</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {t('common:SUBSCRIPTION.total', {count: PTotal})}
            </span>
          </h3>
        </div>

        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
        </div>
      </div>
    </>
  )
}

export {Subscription}
