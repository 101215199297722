import {useEffect, useState} from 'react'
import {SubscriptionAddReq, magicnum} from '../../../services/Request'
import {SALE, SALE2, SubscriptionAdd} from '../../../services/main'
import useCookie from '../../../services/useCookie'
import {toast} from 'react-toastify'
import {addMonths, isNullOrEmpty, moneyFormat} from '../../../services/Utils'
// import { isNullOrEmpty } from "../../../services/Utils"

function Subscription({t, item, onAddSuccess, type}) {
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [mobile, setMobile] = useState('')
  const [skey, setSkey] = useState('grado')
  const [provider, setProvider] = useState('')
  const [is_designer, setIs_designer] = useState('')
  const [duration, setDuration] = useState('1')
  const [bagts, setBagts] = useState('sub1')
  const [hugatsaaEnd, setHugatsaaEnd] = useState('')
  const [huvi, setHuvi] = useState('person')
  const [school_id, setSchoolID] = useState(null)
  const [org_rd, setOrg_rd] = useState('')
  const [price, setPrice] = useState('')
  const [selectedbagtsPrices, setselectedbagtsPrices] = useState([])

  const [sale, setSale] = useState(0)
  const [DesignerID] = useCookie('DesignerID')

  useEffect(() => {
    if (duration > 5 && duration < 12) {
      setSale(SALE2)
    } else if (duration > 11) {
      setSale(SALE)
    } else {
      setSale(0)
    }
  }, [duration, bagts, skey])

  useEffect(() => {
    if (bagts === 'super') {
      setPrice(140000 * ((100 - sale) / 100) * duration)
    }

    if (!isNullOrEmpty(bagts) && !isNullOrEmpty(skey)) {
      const bagtsarr = magicnum.bagts.find((el) => el.skey === skey)
      var tempPrice = bagtsarr[bagts]

      if (!isNullOrEmpty(tempPrice)) {
        setPrice(tempPrice * ((100 - sale) / 100) * duration)
      }
    }
  }, [sale, duration, bagts])

  useEffect(() => {
    setName(item?.name)
    setEmail(item?.email)
    setMobile(item?.mobile)
    setProvider(item?.provider)
    setIs_designer(item?.is_designer)
  }, [item])

  const orderAddSubscription = () => {
    setLoading(true)
    var req = SubscriptionAddReq
    req.order.uid = DesignerID
    req.order.bagts = bagts
    req.order.sungasan_sar = duration
    req.order.bagts_hugatsaa = hugatsaaEnd
    req.order.payment = 'qpay'
    req.order.amount = price
    req.order.status = magicnum.Status.Active
    req.order.org_rd = org_rd
    req.order.school_id = school_id
    req.order.ebarimt_email = item?.email
    req.order.uid = item?.id
    req.order.skey = skey
    req.order.ashiglah_too = 50
    SubscriptionAdd(req, onOrderSubscriptionSuccess, onFailed, 'POST')
  }

  const onOrderSubscriptionSuccess = (response) => {
    setLoading(false)
    onAddSuccess('амжилттай нэмлээ')
  }
  const onFailed = (error) => {
    setLoading(false)
    return toast.error(error)
  }

  return (
    <div className='mx-5 mx-xl-10 pt-10 pb-10 modal-width'>
      <div className='text-center mb-13 w-300px'>
        <h1 className='mb-3'>{name + ' ' + t('common:SUBSCRIPTION.pagetitle')}</h1>
      </div>
      <div>
        <select
          value={huvi}
          onChange={(e) => setHuvi(e.target.value)}
          className='form-select form-select-sm form-select-solid mb-4'
        >
          <option value='person'>{t('common:USERS.person')}</option>
          <option value='company'>{t('common:USERS.company')}</option>
        </select>
        {huvi == 'person' ? (
          <div>
            <input
              className='form-control form-control-solid mb-4'
              type='text'
              value={name}
              placeholder={t('common:USERS.name')}
              disabled
            />
            <input
              className='form-control form-control-solid mb-4'
              type='text'
              value={email}
              placeholder={t('common:USERS.email')}
            />
          </div>
        ) : (
          <input
            className='form-control form-control-solid mb-4'
            type='text'
            value={org_rd}
            placeholder={t('common:SUBSCRIPTION.org_rd')}
            onChange={(e) => setOrg_rd(e.target.value)}
          />
        )}

        <input
          className='form-control form-control-solid mb-4'
          type='text'
          value={mobile}
          placeholder={t('common:USERS.pNumber')}
        />
        <select
          value={skey}
          onChange={(e) => setSkey(e.target.value)}
          className='form-select form-select-sm form-select-solid mb-4'
        >
          <option value='grado'>grado</option>
          <option value='tool'>tool</option>
          <option value='qr'>qr</option>
          <option value='flipbook'>flipbook</option>
        </select>

        <select
          value={bagts}
          onChange={(e) => setBagts(e.target.value)}
          className='form-select form-select-sm form-select-solid mb-4'
        >
          <option value=''>{t('common:validSelect')}</option>

          <option value='sub1'>Стандарт</option>
          <option value='sub2'>Байгууллага</option>
          <option value='sub3'>Боловсрол</option>
          <option value='super'>Супер</option>
        </select>
        <label for='month'>
          <span>month</span>
        </label>
        <input
          id='month'
          onChange={(e) => setDuration(e.target.value)}
          className='form-control form-control-solid mb-4'
          type='number'
          value={duration}
          placeholder={t('common:USERS.duration')}
        />
        {/* {isNullOrEmpty(bagts) ? null : (
          <div>
            <span style={{marginRight: '20px'}}>{moneyFormat(price, 'MNT')}</span>
            {sale}% {t('common:SUBSCRIPTION.off')}
          </div>
        )} */}
      </div>

      <div className='pt-10' style={{textAlign: 'right'}}>
        <button
          onClick={orderAddSubscription}
          className='btn btn-primary align-self-center'
          disabled={loading}
        >
          {loading ? t('common:loading') : t('common:save')}
        </button>
      </div>
    </div>
  )
}

export default Subscription
